import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useRecentFiles = (user=null,idFather=null) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  // const [createFolderTrigger, setCreateFolderTrigger] = useState(false);

  const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
  const urlHostTenant = useSelector((state) => state.urlHost);
  // Este va para los headers
  const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');

      try {
        var response = {}
        if(idFather==null){ // Estoy en home
          
          if (user.rol === "admin") {
            // Es un usuario administrador
            response = await axios.get(`${urlHostTenant}/documents/recentDocument?nameUser=${user.id}&getUsuario=${user.username}`,
              {headers: {
                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                'X-DTS-SCHEMA': xDtsSchemaTenant,
            }}
            );
            // http://hyperoxbiz.localhost:8000/documents/recentDocument/
          } else {
            // worker
            response = await axios.get(`${urlHostTenant}/documents/recentDocument?nameUser=${user.id}&getUsuario=${user.nombreCompleto}`,
              {headers: {
                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                'X-DTS-SCHEMA': xDtsSchemaTenant,
            }}
            );
          }
        }else{ // Estoy ensubcarpeta
  
          if(user.rol === "admin") {
            response = await axios.get(`${urlHostTenant}/documents/document?idFather=${idFather}&getUsuario=${user.username}`,
              {headers: {
                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                'X-DTS-SCHEMA': xDtsSchemaTenant,
            }}
            );
  
          }else{//worke
            response = await axios.get(`${urlHostTenant}/documents/document?idFather=${idFather}&getUsuario=${user.nombre_carpeta}`,
              {headers: {
                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                'X-DTS-SCHEMA': xDtsSchemaTenant,
            }}
            );
  
          }
  
        }
        console.log('files', response)
        setFiles(response.data.data);
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetchData();
  }, [user,idFather]);

  return {files, loading, error};
};

export default useRecentFiles;