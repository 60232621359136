import React from 'react'

export const FooterLogin = () => {
    return (
        <footer className='footer-login'>
            <div className="item-footer">
                <a href="https://www.facebook.com/hyperoxti"><i className="fa-brands fa-facebook" ></i></a>
            </div>

            <div className="item-footer">
                <a href="https://www.instagram.com/hyperox.biz/"><i className="fa-brands fa-instagram"></i></a>
            </div>

            <div className="item-footer">
                <a href="https://mx.linkedin.com/company/hyperox"><i className="fa-brands fa-linkedin"></i></a>
            </div>

            <div className="item-footer">
                <a href="https://www.youtube.com/@hyperox1383/about"><i className="fa-brands fa-youtube"></i></a>
            </div>

            <div className="item-footer">
                <i className="fa-sharp fa-regular fa-envelope"></i>
                <b>soporte@hyperox.biz</b>
            </div>
        </footer>
    )
}
