import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ReactEcharts from 'echarts-for-react'
import '../styles/paneldecontrol.css'

// El dashboard es el WelcomePanel que había antes, se eliminó debido a que eran la misma página (mostraban lo mismo) pero con diferente nombre

export const Dashboard = () => {

    const [seleccion, setSeleccion] = useState('');

    const vistas = {
        'vista-dashboard-usuarios': 'Dashboard Usuarios',
        'vista-dashboard-equipos': 'Dashboard Equipos',
      };
    
      const handleSelectChange = (event) => {
        setSeleccion(event.target.value);
      };
    

      useEffect(() => {
        mostrarVista();
      }, [seleccion]);
    
      // Función para mostrar la vista seleccionada
      const mostrarVista = () => {
        if (seleccion in vistas) {
          // Aquí, renderiza la vista seleccionada
          console.log(`Mostrando la vista: ${vistas[seleccion]}`);
        } else {
          // Renderiza la vista por defecto o un mensaje cuando no hay selección
          console.log('Mostrando vista por defecto');
        }
      };

    useEffect(() => {
        document.title = 'Panel de Control - Lyvian';
        return () => {
            document.title = 'Título Predeterminado';
        };
    }, []);



    return (
        // <!-- ************ VISTA Dashboard General ************ -->
<div id="vista-dashboard-general" >

<div className="navbar_dashboard mb-5">
    <select onChange={handleSelectChange} value={seleccion} id='seleccion'>
        <option value="">Seleccione una opción</option>
        <option value="vista-dashboard-usuarios">Dashboard Usuarios</option>
        <option value="vista-dashboard-equipos">Dashboard Equipos</option>
    </select>
</div>

    {/* <!-- Dashboard General de Usuarios --> */}

    <div class="col" id="general" style={{ display: seleccion === '' ? 'block' : 'none' }}>
        
        <div class="row">
            <div class="col-3">
                <div class="row justify-content-center" >
                    {/* <!-- Licencias --> */}
                    <div class="licencias mb-3"> 
                        <h4><i class="fa-solid fa-user"></i> Licencias Disponibles: 
                        {/* {{licencias_disponibles}} */}
                        </h4>
                    </div>

                    {/* <!-- Almacenamiento de Usuarios --> */}
                    <h2 class="mb-4 d-flex justify-content-center">Almacenamiento Total Usuarios</h2>

                    <div class="mb-4" id="total-usuarios">
                        {/* {{total_size_gb_trabajador_sum | floatformat:2}}   */}
                        GB</div>

                    <div class="storage">
                        <div class="progress mb-4">
                            <div class="progress-bar" role="progressbar" 
                            // style="width: {{ espacio_utilizado_percent_trabajador_sum }}%; background-color: purple;" 
                            aria-valuenow="
                            // {{ espacio_utilizado_percent_trabajador_sum }}
                            " aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                        <div class="space-info d-flex justify-content-between mb-2">
                            <div>0</div>
                            <div>
                                {/* {{ mitad_espacio | floatformat:2 }} */}
                            </div>
                            <div>
                                {/* {{ limite_espacio_tb_trabajador }}  */}
                            TB</div>   
                        </div>
                    </div>

                    <h2>Usuarios Eliminados</h2>
                    <div id="chartEliminados" 
                    // style="width: 300px; height: 300px; display:flex;"
                    ></div>
                </div>  
            </div>  

            <div class="col-3 ">
                <div class="row justify-content-center">
                    <h2> Usuarios Activos</h2>
                    <div id="chartActivos" 
                    // style="width: 300px; height: 300px; display:flex;"
                    ></div>

                    <h2>Usuarios Inactivos</h2>
                    <div id="chartInactivos" 
                    // style="width: 300px; height: 300px; display:flex;"
                    ></div>
                </div>
            </div>

            <div class="col-6 justify-content-start">
                <h3>Actividad Reciente</h3>
                <div id="acitvidadReciente" 
                // style="width: 600px; height: 400px;"
                ></div>
                <div></div>
                <ul class="actividad_reciente">
                    {/* {% for documento in documentos %} */}
                        <li>
                            {/* {{ documento.trabajador }}  */}
                            subió el archivo
                            {/* <b>{{ documento.nombre }}</b> */}
                            </li>
                        <li 
                        // style="color:grey; margin-bottom:10px; list-style-type: none;"
                        >
                            <small>Con un tamaño de : 
                                {/* {{ documento.size | floatformat:2}}  */}
                                MB, el 
                                {/* {{ documento.fecha_de_creacion }} */}
                                </small></li>
                    {/* {% endfor %} */}
                </ul>
            </div> 

        </div>

    </div> 

    {/* <!-- *******************VISTA Dashboard General*************** --> */}
    

    {/* <!-- *******************VISTA Dashboard Usuarios*************** --> */}
    <div id="vista-dashboard-usuarios" style={{ display: seleccion === 'vista-dashboard-usuarios' ? 'block' : 'none' }}>

        <div class="row justify-content">
            <div class="col-3">

                <div class="row justify-content-center" >
                    <div class="licencias mb-3"> 
                        <h3><i class="fa-solid fa-user"> </i> Licencias Disponibles: 
                        {/* {{licencias_disponibles}} */}
                        </h3>
                    </div>
                    <h1 class="mb-4">Almacenamiento Total Usuarios</h1>
                    <div class="mb-4" id="total-usuarios">
                        {/* {{total_size_gb_trabajador_sum | floatformat:2}}   */}
                        0.00 <br /> GB</div>
                    <div class="">
                        <div class="progress mb-4">
                                <div class="progress-bar" role="progressbar" 
                                    // style="width: {{ espacio_utilizado_percent_trabajador_sum }}%; background-color: purple;" 
                                    aria-valuenow="{{ espacio_utilizado_percent_trabajador_sum }}" aria-valuemin="0" aria-valuemax="100">
                                </div>
                        </div>
                        <div class="space-info d-flex justify-content-between mb-4">
                                <div>0</div>
                                <div>
                                    {/* {{ mitad_espacio | floatformat:2 }} */}
                                </div>
                                <div>
                                    {/* {{ limite_espacio_tb_trabajador }}  */}
                                TB</div>   
                        </div>
                    </div>

                    <h1>Usuarios Eliminados</h1>
                    <div id="chartEliminados2" 
                    // style="width: 300px; height: 300px; display:flex;"
                    ></div>
                </div>  

            </div>  

            <div class="col-3 ">
                <div class="row justify-content-center">
                    <h1> Usuarios Activos</h1>
                    <div id="chartActivos2" 
                    // style="width: 300px; height: 300px; display:flex;"
                    ></div>

                    <h1>Usuarios Inactivos</h1>
                    <div id="chartInactivos2" 
                    // style="width: 300px; height: 300px; display:flex;"
                    ></div>
                </div>
            </div>

            <div class="col-6 justify-content-start">
                <h4>Actividad Reciente</h4>
                <div id="acitvidadReciente2" 
                // style="width: 600px; height: 400px;"
                ></div>
                <div></div>
                <ul class="actividad_reciente">
                    {/* {% for documento in documentos %} */}
                        <li>
                            {/* {{ documento.trabajador }}  */}
                            subió el archivo 
                            {/* <b>{{ documento.nombre }}</b> */}
                        </li>
                        <li 
                        // style="color:grey; margin-bottom:10px;   list-style-type: none;"
                        ><small>Con un tamaño de : 
                            {/* {{ documento.size | floatformat:2}}  */}
                            MB, el 
                            {/* {{ documento.fecha_de_creacion }} */}
                            </small></li>
                    {/* {% endfor %} */}
                </ul>
            </div>
            
        </div> 
        
    </div>


    {/* <!-- *******************VISTA Dashboard Equipos *************** --> */}
    <div class=" justify-content-center" id="vista-dashboard-equipos" style={{ display: seleccion === 'vista-dashboard-equipos' ? 'block' : 'none' }}>
        
        <div class="row">
            <div class="col-lg-3 col-sm-2 d-flex justify-content-center">
                <div class="licencias mb-3"> 
                    <h3>Total de Equipos</h3>
                    <h2 class="text-center">
                        {/* {{equipos.count}} */}
                    </h2>
                    <button class="btn" 
                    // style="background:#D0D0D0;width:200px;" 
                    // onclick="mostrarVista('vista-equipos'); limpiarParametrosURL();"
                    >
                    Ver todos + </button>
                </div>
            </div>

            <div class="col-lg-3 col-sm-2">
                <h3 class="mb-4 text-center">Almacenamiento Total Equipos</h3>
                <div class="mb-4" id="total-usuarios">
                    {/* {{almacenamiento_total_equipos | floatformat:2}}   */}
                    0.00 <br /> GB</div>
                <div class="">
                    <div class="progress mb-4">
                    <div class="progress-bar" role="progressbar" 
                    // style="width: {{ porcentaje_almacenamiento_total_equipos }}%; background-color: purple;" 
                    aria-valuenow="{{ porcentaje_almacenamiento_total_equipos }} " aria-valuemin="0" aria-valuemax="100">
                    </div>
                </div>
                <div class="space-info d-flex justify-content-between mb-4">
                    <div>0</div>
                    <div>
                        {/* {{ mitad_espacio | floatformat:2 }} */}
                    </div>
                    <div>
                        {/* {{ limite_espacio }}  */}
                    TB</div>   
                </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-4 justify-content-end" id="equipos-storage">
                <div class="row justify-content-center d-flex">
                    <h4 >Equipos con más almacenamiento</h4>
                    <div id="tamanoEquipoChart" 
                    // style="margin-top:20px; width: 500px; height: 300px;"
                    ></div>
                </div>
            </div>

        </div>

        <div class="row me-4 d-flex" 
        // style=" margin-top:-60px;"
        >
            <h2>Almacenamiento por Equipo</h2>
            {/* {% for equipo in primeros_tres %} */}
                <div class="almacenar-equipos d-flex">
                    <div class="col-2 align-items-center" 
                    // style="display:flex; border-right: 1px solid #D9D9D9;"
                    >
                        <h6>
                            {/* {{equipo.nombre_equipo}} */}
                        </h6>
                    </div>

                    <div class="col-4 d-flex justify-content-center" 
                    // style="border-right: 1px solid #D9D9D9;"
                    >
                        <div class="grafico-equipos">
                            {/* <!-- Contenedor de la gráfica con un ID único para cada equipo --> */}
                        
                            <div id="grafica-{{ equipo.id }}" 
                            // style="width: 600px; height: 100px;"
                            ></div>

                            {/* <!-- Código de la gráfica específico para cada equipo --> */}
                            {/* <script>
                                var option_{{ equipo.id }} = {
                                tooltip: {
                                    trigger: 'item',
                                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                                },
                                
                                series: [
                                    {
                                    type: 'pie',
                                    selectedMode: 'single',
                                    radius: [0, '30%'],
                                    label: {
                                        show: false // Ocultar las etiquetas
                                    },
                                    labelLine: {
                                        show: false // Ocultar las líneas de conexión
                                    },
                                    data: [
                                        { value: {{ equipo.numero_carpetas }}, name: 'Carpetas' },
                                        { value: {{ equipo.numero_documentos }}, name: 'Documentos' },
                                        { value: {{ equipo.numero_versiones }}, name: 'Versiones', selected: true }
                                    ]
                                    },
                                    {
                                    type: 'pie',
                                    radius: ['45%', '60%'],
                                    data: [
                                        { value: {{ equipo.numero_carpetas }}, name: 'Carpetas' },
                                        { value: {{ equipo.numero_documentos }}, name: 'Documentos' },
                                        { value: {{ equipo.numero_versiones }}, name: 'Versiones' }
                                    ]
                                    }
                                ]
                                };

                                // Inicializar la gráfica
                                var myChart_{{ equipo.id }} = echarts.init(document.getElementById('grafica-{{ equipo.id }}'));
                                myChart_{{ equipo.id }}.setOption(option_{{ equipo.id }});
                            </script> */}
                        </div>
                    </div>

                    <div class="row-6 " 
                    // style="font:600 14px/21px Poppins; display:flex;"
                    >
                        <div class="col-4 ms-5">
                            <h6 class="text-muted">Carpetas</h6>
                            <p>
                                {/* {{equipo.numero_carpetas}}  */}
                            total</p> 
                        </div>
                        <div class="col-4 ms-4">
                            <h6 class="text-muted">Archivos</h6>
                            <p>
                                {/* {{equipo.numero_documentos}}   */}
                            total </p>
                        </div>
                        <div class="col-4 ms-4" >
                            <h6 class="text-muted">Versiones</h6>
                            <p>
                                {/* {{equipo.numero_versiones}}   */}
                            total </p>
                        </div>
                    </div>
                </div>
            {/* {% endfor %} */}
        </div>
    </div>
</div>
    )
}
