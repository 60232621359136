import React, { useCallback, useEffect, useState } from 'react'
// import { Link } from 'react-router-dom';
import './styles/register_empresa.css'
import { Footer } from './shared/Footer';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux'

export const RegistroEmpresa = () => {

    const [username,setUserName] = useState('');
    const [password,setPassword] = useState('');
    const [companyName,setCompanyName] = useState('');
    const [image, setImage] = useState('')
    const [giroEmpresarial,setGiroEmpresarial] = useState('');
    const [rfc,setRFC] = useState('');
    const [correoEmpresarial,setCorreoEmpresarial] = useState('');
    const [domicilio,setDomicilio] = useState('');
    const [colonia,setColonia] = useState('');
    const [pais,setPais] = useState('');
    const [estado,setEstado] = useState('');
    const [ciudad,setCiudad] = useState('');
    const [codigoPostal,setCodigoPostal] = useState('');
    const [numExterior,setNumExterior] = useState('');
    const [numInterior,setNumInterior] = useState('');
    const [numTelefonoEmpresa,setNumTelefonoEmpresa] = useState('');
    const [nameAdmi,setNameAdmi] = useState('');
    const [apellidosAdmi,setApellidosAdmi] = useState('');
    const [correoAdmi,setCorreoAdmi] = useState(''); // correo personal del administrador
    const [tipoSuscripcion,setTipoSuscripcion] = useState('');
    const [almacenamiento,setAlmacenamiento] = useState('');
    const [licencias,setLicencias] = useState('');
    const navigate = useNavigate();
    const [activeContenido, setActiveContenido] = useState('contenido-1');

    const [isDisabled, setIsDisabled] = useState(true);
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
        // Aquí puedes manejar el archivo como desees, como cargarlo a tu estado de React o enviarlo a tu servidor
    };

    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);

    useEffect(() => {
        document.title = 'Registrar Empresa - Lyvian';
        return () => {
            document.title = 'Título Predeterminado';
        };
    }, []);

    const onSubmit = useCallback (async (event) => {
        event.preventDefault();

        // Verificar si todos los campos del formulario están completos
        if (
            username.trim() === '' ||
            password.trim() === '' ||
            companyName.trim() === '' ||
            giroEmpresarial.trim() === '' ||
            rfc.trim() === '' ||
            correoEmpresarial.trim() === '' ||
            domicilio.trim() === '' ||
            colonia.trim() === '' ||
            pais.trim() === '' ||
            estado.trim() === '' ||
            ciudad.trim() === '' ||
            codigoPostal.trim() === '' ||
            numExterior.trim() === '' ||
            numInterior.trim() === '' ||
            numTelefonoEmpresa.trim() === '' ||
            nameAdmi.trim() === '' ||
            apellidosAdmi.trim() === '' ||
            correoAdmi.trim() === '' ||
            tipoSuscripcion.trim() === '' ||
            almacenamiento.trim() === '' ||
            licencias.trim() === ''
        ) {
            // Si algún campo está vacío, mostrar alerta al usuario
            alert('Por favor completa todos los campos del formulario.');
            return;
        }

        try {
            console.log("1")
            await axios.post(`http://localhost:8000/tenants/create_tenant/`, {
                // `http://hyperox.localhost:8000/users/customuser/`
                "username": username,
                "password": password,
                "nombre": companyName,
                "giro_empresarial": giroEmpresarial,
                "rfc": rfc,
                "correo_empresarial": correoEmpresarial,
                "domicilio": domicilio,
                "colonia": colonia,
                "pais": pais,
                "estado": estado,
                "ciudad": ciudad,
                "codigo_postal": codigoPostal,
                "numero_exterior": numExterior,
                "numero_interior": numInterior,
                "celular": numTelefonoEmpresa,
                "empresa": nameAdmi,
                "apellidos": apellidosAdmi,
                "email": correoAdmi,
                // "rol": rol, 
                "tipo_de_suscripcion": tipoSuscripcion,
                "almacenamiento": almacenamiento,
                "licencias": licencias,
                "imagen": null,
            }, 
            {
                headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                }).then(({data}) => {
                    alert("¡Registro exitoso!");
                    console.log(data)
                    navigate('/welcomeadmi');

                })
            
        } catch (error){
            console.log('Error', error) //ilustracion para el usuario
            alert('Ocurrió un error durante el registro. Por favor inténtalo nuevamente.');
        }finally{
            // setIsLoading(false) 
            // registrarlo y redireccionarlo al login
        }
    }, [username, password, companyName, giroEmpresarial,rfc, correoEmpresarial, domicilio, colonia, pais, estado, ciudad, codigoPostal,numExterior, numInterior, numTelefonoEmpresa, nameAdmi, apellidosAdmi, correoAdmi, tipoSuscripcion, almacenamiento, licencias,navigate]);

    const handleContenidoClick = (contenido) => {
        setActiveContenido(contenido);
    };

    const handleSiguienteClick = (event) => {
        event.preventDefault();
        // Lógica para manejar el clic en el botón de siguiente
        // Determina el contenido activo actual
        const pasos = ['contenido-1', 'contenido-2', 'contenido-3'];
        const currentIndex = pasos.indexOf(activeContenido);
        const newIndex = Math.min(currentIndex + 1, pasos.length - 1); // Evita índices mayores al último paso
        const nuevoContenido = pasos[newIndex];
        setActiveContenido(nuevoContenido);
    };

    const handleRegresarClick = (event) => {
        event.preventDefault(); // Detener la propagación del evento
        // Lógica para manejar el clic en el botón de regresar
        // Determina el contenido activo actual
        if (activeContenido === 'contenido-1') {
            window.location.href = '/welcomeadmi'; // Redirige al escritorio si está en el paso 1
        } else {
            // Lógica para manejar el clic en el botón de regresar
            // Determina el contenido activo actual
            const pasos = ['contenido-1', 'contenido-2', 'contenido-3'];
            const currentIndex = pasos.indexOf(activeContenido);
            const newIndex = Math.max(currentIndex - 1, 0); // Evita índices negativos
            const nuevoContenido = pasos[newIndex];
            setActiveContenido(nuevoContenido);
        }
    };

    const handleInputEmailChange = (correo, setCorreo) => (e) =>{
        const value = e.target.value;
        setCorreo(value);
    
        if (value.endsWith('@gmail.com')) {
            alert('No se permiten correos de Gmail.');
            setIsDisabled(true);
        } else if (value !== '') {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    };

    const invocarPostTenant = async (event) => {
        event.preventDefault();
        
        // Asegurarnos de que la URL ha sido configurada antes de proceder
        await new Promise(resolve => setTimeout(resolve, 100)); // Breve retraso para asegurar que `setUrl` se haya completado
        onSubmit(event);
    };


    // useEffect((event) => {
    //     if (url) {
    //         onSubmit(event);
    //     }
    // }, [url]);
    
    return (
        <div className='body-registroempresa'>

            <h1 className="title-hyper">¡Bienvenido a Lyvian!</h1>
            <p className="titulo-registro">Registro de Empresa</p>

            <div className="pasos">
                <div className={`paso paso-1 ${activeContenido === 'contenido-1' ? 'paso-activo' : ''}`} onClick={() => handleContenidoClick('contenido-1')}>
                    <p className="texto-informacion-empresarial">Información empresarial</p>
                    <div className="circulo">
                        <p className="texto-paso-1"> 1 </p>
                    </div>
                </div>

                <div className={`paso paso-2 ${activeContenido === 'contenido-2' ? 'paso-activo' : ''}`} onClick={() => handleContenidoClick('contenido-2')}>
                    <p className="texto-perfil-administrador">Perfil Administrador</p>
                    <div className="circulo-2">
                        <div className="texto-paso-2">2</div>
                    </div>
                </div>

                <div className={`paso paso-3 ${activeContenido === 'contenido-3' ? 'paso-activo' : ''}`}  onClick={() => handleContenidoClick('contenido-3')}>
                    <p className="texto-proceso-de-compra">Proceso de Compra</p>
                    <div className="circulo-3">
                        <p className="texto-paso-3">3</p>
                    </div>
                </div>
            </div>

            <form className="form-register" encType="multipart/form-data" onSubmit={invocarPostTenant}>
                {/* Datos Generales */}
                <div className="contenido" id="contenido-1" style={{ display: activeContenido === 'contenido-1' ? 'block' : 'none' }}>
                    <p className="titulo-empresa"><b>Tú Empresa</b></p>
                    <p className="titulo-texto">Ingresa los datos necesarios para dar el primer paso</p>

                    {/* <!-- EMPRESA --> */}
                    {/* <!-- Campos empresa y giro empresarial --> */}
                    <div className="form-field two-inputs">
                        <input type="text" name="" id="" value={companyName} onChange={(e) => setCompanyName(e.target.value)} className="empresa-input-1" placeholder = "Nombre de la Compañía"/>
                        <select className="form-select" aria-label="Default select example" onChange={(e) => setGiroEmpresarial(e.target.value)}>
                            <option selected>Giro Empresarial</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>

                    <div className="form-field two-inputs">
                        <input type="text" name="" id="" onChange={(e) => setRFC(e.target.value)}  className="empresa-input-1" placeholder = "RFC"/>
                        <input type="text" name="" id="" value={correoEmpresarial} onChange={handleInputEmailChange(correoEmpresarial, setCorreoEmpresarial)} className="empresa-input-1" placeholder = "Correo Empresarial"/>
                    </div>

                    {/* <!-- Campo domicilio --> */}
                    <div className="form-field two-inputs">
                        <input type="text" name="" id="" onChange={(e) => setDomicilio(e.target.value)} className="empresa-input-1" placeholder = "Domicilio"/>
                        <input type="text" name="" id="" onChange={(e) => setColonia(e.target.value)} className="empresa-input-1" placeholder = "Colonia"/>
                    </div>

                    {/* <!-- Campos Estado y país --> */}
                    <div className="form-field two-inputs">
                        <select className="form-select" aria-label="Default select example" onChange={(e) => setPais(e.target.value)}>
                            <option selected>País</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                        <select className="form-select" aria-label="Default select example" onChange={(e) => setEstado(e.target.value)}>
                            <option selected>Estado</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>        

                    {/* <!-- Campos colonia, código postal y ciudad --> */}
                    <div className="form-field four-inputs">
                        <select className="form-select" aria-label="Default select example" onChange={(e) => setCiudad(e.target.value)}>
                            <option selected>Ciudad</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                        <input type="text" name="" id="" onChange={(e) => setCodigoPostal(e.target.value)} className="empresa-input-1" placeholder = "Código Postal"/>
                        <input type="text" name="" id="" onChange={(e) => setNumExterior(e.target.value)} className="empresa-input-1" placeholder = "No. Exterior"/>
                        <input type="text" name="" id="" onChange={(e) => setNumInterior(e.target.value)} className="empresa-input-1" placeholder = "No. Interior"/>
                    </div>
                    
                    {/* <!-- Campos correo, celular / mfa -> significa metodo de autentificación --> */}
                    <div className="form-field two-inputs">
                        <input type="text" name="" id="" onChange={(e) => setNumTelefonoEmpresa(e.target.value)} className="empresa-input-1" placeholder = "Número de teléfono"/>
                        <input className="form-control" type="file" id="inputFile" onChange={handleFileChange} />
                    </div>
                </div>

                {/* <!-- PERFIL ADMINISTRADOR --> */}
                <div className="contenido" id="contenido-2" style={{ display: activeContenido === 'contenido-2' ? 'block' : 'none' }}>
                    <p className="titulo-empresa"><b>Perfil Administrador</b></p>
                    <p className="titulo-texto">Ingresa los datos del responsable de la cuenta para continuar </p>

                    {/* <!-- Campos Nombre y Apellidos --> */}
                    <div className="form-field two-inputs">
                        <input type="text"  className="empresa-input-1" onChange={(e) => setNameAdmi(e.target.value)} placeholder = "Nombre(s)"/>
                        <input type="text"  className="empresa-input-1" onChange={(e) => setApellidosAdmi(e.target.value)} placeholder = "Apellido(s)"/>
                    </div>

                    {/* <!-- Campo usuario y correo electrónico --> */}
                    <div className="form-field two-inputs">
                        <input type="text" className="empresa-input-2" onChange={(e) => setUserName(e.target.value)} placeholder = "Nombre de Usuario"/>
                        <input type="text" className="empresa-input-2" value={correoAdmi}  onChange={handleInputEmailChange(correoAdmi, setCorreoAdmi)} placeholder = "Correo Electrónico"/>
                    </div>

                    {/* <!-- Campos Celular y Empresa --> */}
                    <div className="form-field two-inputs">
                        <input type="text" className="empresa-input-2"  placeholder = "Número de teléfono"/>
                        <input type="text" className="empresa-input-2" value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder = "Nombre de la Compañía"/> {/** Este campo es para saber a que empresa pertenece el administrador */}
                    </div>        
                    
                    {/* <!-- Campos contraseña y confirmar contraseña --> */}
                    <div className="form-field two-inputs">
                        <input type="text" className="empresa-input-2"  placeholder = "Ingresa una Contraseña"/>
                        <input type="text" className="empresa-input-2" onChange={(e) => setPassword(e.target.value)} placeholder = "Confirma la Contraseña"/>
                    </div>
                </div>

                {/* <!-- PROCESO DE COMPRA --> */}
                <div className="contenido" id="contenido-3" style={{ display: activeContenido === 'contenido-3' ? 'block' : 'none' }}>
                    <p className="titulo-empresa"><b>Proceso de Compra</b></p>
                    <p className="titulo-texto">Define tu plan, selecciona la opción perfecta para cumplir con tus necesidades</p>

                    {/* <!-- Campos Elegir el Plan / Tipo de Suscripción --> */}
                    <div className="form-field full-input">
                        <select className="form-select" onChange={(e) => setTipoSuscripcion(e.target.value)} aria-label="Default select example">
                            <option selected>Tipo de Suscripción</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>

                    {/* <!-- Campo Almacenamiento --> */}
                    <div className="form-field">
                        <select className="form-select" onChange={(e) => setAlmacenamiento(e.target.value)} aria-label="Default select example">
                            <option selected>Almacenamiento (TB)</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>

                    {/* <!-- Campo Licencias --> */}
                    <div className="form-field">
                        <input type="text" name="" id="" className="empresa-input-1" onChange={(e) => setLicencias(e.target.value)} placeholder = "No. de Licencias"/>
                    </div>

                    {/* <!-- Checkbox de Términos y Condiciones--> */}
                    <div className="terminos">
                        <input type="checkbox" id="terminos-checkbox" name="checkpoint"/>
                        <p className="terminos-link">Al hacer click estarás aceptando las Políticas de la empresa, así como los Términos y Condiciones.</p>
                    </div>

                </div>
            
                {/* Botones regresar y siguiente */}
                <div className="regresar-y-siguiente d-flex justify-content-between">
                    {/* Botón regresar */}
                    <span id="regreso-welcome" className="div-regresar ">
                        <a href="#/" className='regresar'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <path d="M14.25 15L19.125 19.875C19.3542 20.1042 19.4688 20.3958 19.4688 20.75C19.4688 21.1042 19.3542 21.3958 19.125 21.625C18.8958 21.8542 18.6042 21.9688 18.25 21.9688C17.8958 21.9688 17.6042 21.8542 17.375 21.625L11.625 15.875C11.5 15.75 11.4113 15.6146 11.3588 15.4688C11.3063 15.3229 11.2804 15.1667 11.2812 15C11.2812 14.8333 11.3075 14.6771 11.36 14.5313C11.4125 14.3854 11.5008 14.25 11.625 14.125L17.375 8.375C17.6042 8.14583 17.8958 8.03125 18.25 8.03125C18.6042 8.03125 18.8958 8.14583 19.125 8.375C19.3542 8.60417 19.4688 8.89583 19.4688 9.25C19.4688 9.60417 19.3542 9.89583 19.125 10.125L14.25 15Z" fill="#765FA3" />
                            </svg>
                        </a>
                        <a href="/welcomeadmi" className="regresar" onClick={handleRegresarClick}>
                            {activeContenido === 'contenido-1' ? 'Regresar al Escritorio' : 'Regresar al paso Anterior'}
                        </a>
                    </span>

                    {/* Botón siguiente */}
                    <button id="btn-siguiente" className="button-register " style={{ display: activeContenido === 'contenido-3' ? 'none':'block' }} onClick={handleSiguienteClick} disabled={isDisabled}>
                        <b> Siguiente </b> <i className="fa-solid fa-angle-right"></i>
                    </button>

                    {/* Botón de registrar */}
                    {activeContenido === 'contenido-3' && (
                        <button id="btn-registro" name="registro_empresa" className="button-register">
                            <b>Registrar</b>
                        </button>
                    )}
                </div>
            </form>
            <Footer/>
        </div>
    )
}
