import React, { useCallback, useEffect, useState } from 'react'
import { es } from 'date-fns/locale';
import imagenfolder from '../assets/folder.png';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../router/Auth';
// import useUsers from '../../hooks/useUsers';
import { usePapeleraFolders } from '../../hooks/usePapeleraFolders';
import { usePapeleraFiles } from '../../hooks/usePapeleraFiles';
import { usePapeleraVersionesFiles } from '../../hooks/usePapeleraVersionesFiles';
import { useSelector } from 'react-redux';


export const VistaAdmiPapelera = () => {
    const { format } = require('date-fns');

    const {user} = useAuth();

    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);


    const {folders, setIsLoadingFolders} = usePapeleraFolders(user, null, null);
    const {files, setIsLoading: filesIsLoading} = usePapeleraFiles(user, null);
    const {filesVersiones, setIsLoadingVersions: filesIsLoadingVersion} = usePapeleraVersionesFiles(user, null);


    // Restaurar
    const [restaurarFoldersID, setRestaurarFoldersID] = useState([]); 
    const [restaurarDocumentsID, setRestaurarDocumentsID] = useState([]); 
    const [restaurarDocumentsVersionID, setRestaurarDocumentsVersionID] = useState([]); 

    // Eliminar Definitivamente
    const [deleteFoldersID, setDeleteFoldersID] = useState([]); 
    const [deleteDocumentsID, setDeleteDocumentsID] = useState([]); 
    const [deleteDocumentsVersionID, setDeleteDocumentsVersionID] = useState([]); 

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Papelera - Lyvian'; // Cambia el título de la página cuando el componente se monta

        return () => {
            document.title = 'Lyvian'; // Restaura el título predeterminado cuando el componente se desmonta
        };
    }, []);

    // Restaurar una Carpeta
    const onRestaurarFolder= useCallback (async () => {
        try {
            await axios.delete(`${urlHostTenant}/folders/restaurarCarpeta/${restaurarFoldersID}`, {}, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate, restaurarFoldersID]);

    // Modal para Restaurar una Carpeta
    const openModelRestaurarFolder = (event) => {
        event.preventDefault()
        const idfolder = event.currentTarget.getAttribute('idFolder');
        setRestaurarFoldersID(idfolder);
    }

    // Eliminar una Carpeta
    const onDeleteFolder= useCallback (async () => {
        try {
            await axios.delete(`${urlHostTenant}/folders/papelerafolder/${deleteFoldersID}`, {}, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate, deleteFoldersID]);

    // Modal para Eliminar una Carpeta
    const openModelDeleteFolder = (event) => {
        event.preventDefault()
        const idfolderdelete = event.currentTarget.getAttribute('idFolderDelete');
        setDeleteFoldersID(idfolderdelete);
    }

    // --------------------------------------------------------
    
    // Restaurar un Documento
    const onRestaurarDocument= useCallback (async () => {
        try {
            await axios.delete(`${urlHostTenant}/documents/restaurarDocumento/${restaurarDocumentsID}`, {},
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate, restaurarDocumentsID]);

    // Modal para Restaurar un Documento 
    const openModelRestaurarDocument = (event) => {
        event.preventDefault()
        const iddocument = event.currentTarget.getAttribute('idDocument');
        setRestaurarDocumentsID(iddocument);
    }

    // Eliminar un Documento
    const onDeleteDocument= useCallback (async () => {
        try {
            await axios.delete(`${urlHostTenant}/documents/papeleraDocumento/${deleteDocumentsID}`, {}, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate, deleteDocumentsID]);

    // Modal para Eliminar un Documento
    const openModelDeleteDocument = (event) => {
        event.preventDefault()
        const iddocumentdelete = event.currentTarget.getAttribute('idDocumentDelete');
        setDeleteDocumentsID(iddocumentdelete);
    }
    // --------------------------------------------------

    // Restaurar una Versión de Documento
    const onRestaurarDocumentVersion= useCallback (async () => {
        try {
            await axios.delete(`${urlHostTenant}/documents/restaurarVersion/${restaurarDocumentsVersionID}`, {}, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate, restaurarDocumentsVersionID]);

    // Modal para Restaurar una Version de Documento
    const openModelRestaurarDocumentVersion = (event) => {
        event.preventDefault()
        const iddocumentversion = event.currentTarget.getAttribute('idDocumentVersion');
        setRestaurarDocumentsVersionID(iddocumentversion);
    }

    // Eliminar una Version del Documento
    const onDeleteDocumentVersion= useCallback (async () => {
        try {
            await axios.delete(`${urlHostTenant}/documents/papeleraDocumentoVersion/${deleteDocumentsVersionID}`, {}, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate, deleteDocumentsVersionID]);

    // Modal para Eliminar una Version de Documento
    const openModelDeleteDocumentVersion = (event) => {
        event.preventDefault()
        const idversiondelete = event.currentTarget.getAttribute('idDocumentVersionDelete');
        setDeleteDocumentsVersionID(idversiondelete);
    }

    return (
        <>
            <div className="vista" >

                {/* CARPETASS  */}
                <div className="oculto" id="carpetas-filtradas">
                    <div className="carpetas" id="carpets">
                        <table className="table-hover">
                            <thead>
                                <tr className="fila-tabla">
                                    <th className="encabezado-tabla"></th>
                                    <th className="encabezado-tabla">Nombre </th>
                                    {/* <th className="encabezado-tabla">Tamaño</th> */}
                                    <th className="encabezado-tabla">Ubicación</th>
                                    <th className="encabezado-tabla">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                folders.map((folder,index) => (
                                    <tr className="fila-tabla" key={index}>

                                        {/* <!-- Imagen de la Carpeta --> */}
                                        <td className="imagen">
                                            <a href="#/" className="option-link d-flex justify-content-center">
                                                <img src={imagenfolder} id="img-folder" alt="Imagen de una Carpeta"/>
                                            </a>
                                        </td>
                                        
                                        {/* <!-- Nombre de la Carpeta --> */}
                                        <td className="nombre">
                                            <div className="info-folder puntitos">
                                                <a id="folder-name" href="#/" className="option-link nombre-carpeta"><i className="fa-solid fa-folder-open me-2 ml-2 icono-carpeta"></i>
                                                {folder.nombre_carpeta}
                                                </a>
                                            </div>
                                        </td>

                                        {/* <!-- Tamaño --> */}
                                        {/* <td className="tamaño">
                                            
                                        </td> */}

                                        {/* <!-- Ubicación de la Carpeta --> */}
                                        <td className="ubicacion">
                                            Papelera /
                                        </td>

                                        {/* <!-- Fecha --> */}
                                        <td className="fecha">
                                            {format(new Date(folder.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })}
                                        </td>

                                        {/* <!-- Días Transcurridos --> */}
                                        <td className="dias-papelera">
                                            <p className="col-auto dias-transcurridos text-center pt-1 pb-1 "> 
                                            Faltan: {folder.dias_transcurridos} días
                                            </p>
                                        </td>
                                        
                                        {/* <!-- Tres Puntitos --> */}
                                        <td className="tres-puntitos align-items-start">  
                                            <div className="dropdown">
                                                <div className="cursor" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i  className="fa fa-ellipsis-v fa-lg"></i>
                                                </div>
                                                
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item" href="#/" data-bs-toggle="modal" idFolder={folder.id} onClick={openModelRestaurarFolder} data-bs-target={`#restaurar_carpeta_papelera_modal${folder.id}`} >
                                                            <i className="fa-solid fa-trash-arrow-up"> </i> Restaurar
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <form id="eliminarForm_carpeta_" method="POST" action="">
                                                            <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#eliminar_carpeta_papelera_modal${folder.id}`} idFolderDelete={folder.id} onClick={openModelDeleteFolder}>
                                                                <i className="fa-solid fa-trash-can"> </i> Eliminar
                                                            </a>
                                                        </form>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td> 

                                        {/* <!-- Modal de restaurar_carpeta_papelera_modal --> */}
                                        <div className="modal" id={`restaurar_carpeta_papelera_modal${folder.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                <div className="modal-content">

                                                    <div className="modal-header">
                                                        <h5 className="modal-title col-11 d-flex justify-content-center" id="exampleModalLabel"> Restaurar Carpeta </h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>

                                                    <div className="modal-body text-center ">
                                                        ¿Quieres restaurar <b>
                                                        "{folder.nombre_carpeta}"
                                                        </b>?
                                                        <p className ="nota">Si la versión actual no tiene una carpeta principal, se creará automáticamente en el <b>Escritorio.</b></p>
                                                    </div>

                                                    <div className="modal-footer d-flex justify-content-center ">
                                                        <button type="button" className="mr-2 btn btn-gris" data-bs-dismiss="modal"><i className="fa-solid fa-ban" 
                                                        ></i> Cancelar</button>
                                                        <a href="#/" className="btn btn-morado" idFolderDelete={folder.id} onClick={onRestaurarFolder}><i className="fa-solid fa-trash-arrow-up"> </i> Restaurar</a>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- Fin de modal de restaurar_carpeta_papelera_modal --> */}
                            
                                        {/* <!-- Modal Borrar Carpeta --> */}
                                        <div className="modal" id={`eliminar_carpeta_papelera_modal${folder.id}`} tabIndex="-1" role="dialog" aria-labelledby="confirmarModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                <div className="modal-content">

                                                    <div className="modal-header">
                                                        <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel"> Eliminar Definitivamente </h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>

                                                    <div className="modal-body text-center">
                                                        <p>¿Estás seguro de que deseas eliminar definitivamente la carpeta <b>
                                                        "{folder.nombre_carpeta}" ?
                                                        </b></p>
                                                    </div>

                                                    <div className="modal-footer d-flex justify-content-center">
                                                        <button type="button" className="mr-2 btn btn-gris" data-bs-dismiss="modal"><i className="fa-solid fa-ban" 
                                                        > </i> Cancelar</button>
                                                        <button type="button" className="btn btn-danger" idFolder={folder.id} onClick={onDeleteFolder}><i className="fa-solid fa-trash-can"> </i> Eliminar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- Fin Modal --> */}
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* <!-- DOCUMENTOSSSSSSSS --> */}
                <div className="oculto" id="documentos-filtrados">
                    <div className="documentos document-grid" id="documents">
                        <table className="table-hover">
                            <thead id="tabla-documentos">
                                <tr className="fila-tabla">
                                    <th className="encabezado-tabla"></th>
                                    <th className="encabezado-tabla pl-2">Nombre </th>
                                    {/* <th className="encabezado-tabla">Tamaño</th> */}
                                    <th className="encabezado-tabla">Ubicación</th>
                                    <th className="encabezado-tabla">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    files.map((file, index) => {
                                        return (
                                            <tr className="fila-tabla" key={index}>

                                                {/* <!-- Imagen del Documento --> */}
                                                <td className="imagen">
                                                    <div className="rectangle-86">
                                                    </div>
                                                    <div className="doc"> DOC </div>
                                                    <a href="#/" className="option-link d-flex justify-content-center">
                                                        <svg id="img-documento" alt="Icono de un Documento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 84" fill="none">
                                                        <path d="M23.5 7.44263C21.5109 7.44263 19.6032 8.17134 18.1967 9.46847C16.7902 10.7656 16.5 12.7748 16.5 14.6093V42.026V69.6926C16.5 71.527 17.5935 73.2863 19 74.5834C20.4065 75.8806 21.5109 76.6093 23.5 76.6093H68.5C70.4891 76.6093 72.3968 75.8806 73.8033 74.5834C75.2098 73.2863 75.5 71.527 75.5 69.6926V48.9426V28.1926L53.5 7.44263H23.5ZM21.5 12.526H51V27.526L70.5 28.1926V48.9426V71.1093H46.5H21.5V42.026V12.526ZM31 42.026V46.1093H45.5H61V42.026H31ZM31 56.1093V60.1093H41H50V56.1093H41H31Z" fill="black"/>
                                                        </svg>
                                                    </a>
                                                </td>    

                                                {/* <!-- Nombre del Documento --> */}
                                                <td className="nombre">
                                                    <div className="info-folder puntitos">                        
                                                        <a className="option-link nombre-carpeta" id="folder-name" href="#/"><i className="fa-regular fa-file-lines me-2 ml-2 icono-doc"> </i>
                                                        {file.nombre}.{file.extension}
                                                        </a>
                                                    </div>
                                                </td>
                                                
                                                {/* <!-- Tamaño del Documento --> */}
                                                {/* <td className="tamaño">
                                                    
                                                </td> */}

                                                {/* <!--  Ubicación del Documento --> */}
                                                <td className="ubicacion">
                                                    Papelera /
                                                </td>
                                                
                                                {/* <!-- Fecha de Creación del Documento --> */}
                                                <td className="fecha">
                                                    {format(new Date(file.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })}
                                                </td>

                                                {/* <!-- Días Papelera --> */}
                                                <td className="dias-papelera">
                                                    <p className="col-auto dias-transcurridos text-center pt-1 pb-1"> 
                                                    Faltan: {file.dias_transcurridos} días
                                                    </p> 
                                                </td>
                                                
                                                {/* <!-- Tres Puntitos --> */}
                                                <td className="tres-puntitos">
                                                    <div className="dropdown">
                                                        <div className="cursor" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i  className="fa fa-ellipsis-v fa-lg"></i>
                                                        </div>
                                                            
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <a className="dropdown-item" href="#/" idDocument={file.id} onClick={openModelRestaurarDocument}data-bs-toggle="modal" data-bs-target={`#confirmarModalDocRestaurar${file.id}`} >
                                                                    <i className="fa-solid fa-trash-arrow-up"></i> Restaurar
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#confirmarModalDoc${file.id}`} idDocumentDelete={file.id} onClick={openModelDeleteDocument} >
                                                                    <i className="fa-solid fa-trash-can"></i> Eliminar
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div> 
                                                </td>

                                                {/* <!-- Modal Restaurar documento--> */}
                                                <div className="modal" id={`confirmarModalDocRestaurar${file.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">

                                                            <div className="modal-header">
                                                                <h5 className="modal-title col-11 d-flex justify-content-center" id="exampleModalLabel"> Restaurar Documento </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>

                                                            <div className="modal-body text-center">
                                                                ¿Deseas restaurar el documento <b>"{file.nombre}.{file.extension}"</b>?
                                                            </div>

                                                            <div className="modal-footer d-flex  justify-content-center">
                                                                <button type="button" className="btn btn-gris" data-bs-dismiss="modal"><i className="fa-solid fa-ban" 
                                                                > </i> Cancelar </button>
                                                                <a href="#/" className="btn btn-morado" idDocument={file.id} onClick={onRestaurarDocument}><i className="fa-solid fa-trash-arrow-up"> </i> Restaurar </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <!-- Eliminar documento definitivamente --> */}
                                                <div className="modal" id={`confirmarModalDoc${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="confirmarModalDocLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                                        <div className="modal-content">

                                                            <div className="modal-header">
                                                                <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel"> Eliminar Definitivamente el Documento</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            
                                                            <div className="modal-body text-center">
                                                                <p>¿Estás seguro de que deseas eliminar definitivamente el documento <b>
                                                                "{file.nombre}.{file.extension}"
                                                                </b></p>
                                                            </div>
                                                            
                                                            <div className="modal-footer d-flex justify-content-center">
                                                                <button type="button" className="btn btn-gris" data-bs-dismiss="modal"><i className="fa-solid fa-ban"
                                                                ></i> Cancelar </button>

                                                                <button type="button" className="btn btn-danger" idDocumentDelete={file.id} onClick={onDeleteDocument} ><i className="fa-solid fa-trash-can"> </i> Eliminar </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- Fin Modal Confirmar Eliminación --> */}
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                
                {/* <!-- Versiones de Documentos --> */}
                <div className="oculto" id="documentos-filtrados-versiones">
                    <div className="documentos document-grid" id="documents">
                        <table className="table-hover">
                            <thead id="tabla-documentos">
                                <tr className="fila-tabla">
                                    <th className="encabezado-tabla"></th>
                                    <th className="encabezado-tabla pl-2">Nombre </th>
                                    {/* <th className="encabezado-tabla">Tamaño</th> */}
                                    <th className="encabezado-tabla">Ubicación</th>
                                    <th className="encabezado-tabla">Fecha</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    filesVersiones.map((fileversions,index) => {
                                        if (fileversions.status_documento.toString() === 'false') {
                                            return(
                                                
                                                <tr className="fila-tabla" key={index}>
                                                    {/* <!-- Imagen del Documento --> */}
                                                    <td className="imagen">
                                                        <div className="rectangle-86">
                                                        </div>
                                                        <div className="doc"> DOC </div>
                                                        <a href="#/" className="option-link d-flex justify-content-center">
                                                            <svg id="img-documento" alt="Icono de un Documento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 84" fill="none"><path d="M23.5 7.44263C21.5109 7.44263 19.6032 8.17134 18.1967 9.46847C16.7902 10.7656 16.5 12.7748 16.5 14.6093V42.026V69.6926C16.5 71.527 17.5935 73.2863 19 74.5834C20.4065 75.8806 21.5109 76.6093 23.5 76.6093H68.5C70.4891 76.6093 72.3968 75.8806 73.8033 74.5834C75.2098 73.2863 75.5 71.527 75.5 69.6926V48.9426V28.1926L53.5 7.44263H23.5ZM21.5 12.526H51V27.526L70.5 28.1926V48.9426V71.1093H46.5H21.5V42.026V12.526ZM31 42.026V46.1093H45.5H61V42.026H31ZM31 56.1093V60.1093H41H50V56.1093H41H31Z" fill="black"/>
                                                            </svg>
                                                        </a>
                                                    </td>

                                                    {/* <!-- Nombre del Documento --> */}
                                                    <td className="nombre">
                                                        <div className="info-folder puntitos">                        
                                                            <a className="option-link nombre-carpeta" id="folder-name" href="#/"><i className="fa-regular fa-file-lines me-2 ml-2 icono-doc"> </i>
                                                            {fileversions.nombre_documento_padre}{fileversions.version}.{fileversions.extension} VE
                                                            </a>   
                                                        </div>
                                                    </td>
                                                    
                                                    {/* <!-- Tamaño del Documento --> */}
                                                    {/* <td className="tamaño">
                                                        
                                                    </td> */}

                                                    {/* <!--  Ubicación del Documento --> */}
                                                    <td className="ubicacion">
                                                        Papelera /
                                                    </td>
                                                    
                                                    {/* <!-- Fecha de Creación del Documento --> */}
                                                    <td className="fecha">
                                                        {format(new Date(fileversions.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy")}
                                                    </td>

                                                    {/* <!-- Días Transcurridos --> */}
                                                    <td className="dias-papelera">
                                                        <p className="col-auto dias-transcurridos text-center pt-1 pb-1"> 
                                                        Faltan: {fileversions.dias_transcurridos} días
                                                        </p> 
                                                    </td>
                                                    
                                                    {/* <!-- Tres Puntitos --> */}
                                                    <td className="tres-puntitos">  
                                                        <div className="dropdown mt-2">
                                                            <div className="cursor" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i  className="fa fa-ellipsis-v fa-lg" ></i>
                                                            </div>

                                                            <ul className="dropdown-menu">
                                                                <li>
                                                                    <a className="dropdown-item" href="#/" idDocumentVersion={fileversions.id} onClick={openModelRestaurarDocumentVersion} data-bs-toggle="modal" data-bs-target={`#confirmarModalDocRestaurarVersion${fileversions.id}`}>
                                                                        <i className="fa-solid fa-trash-arrow-up"></i> Restaurar
                                                                    </a>
                                                                </li>

                                                                <li>
                                                                    <a className="dropdown-item" href="#/" idDocumentVersionDelete={fileversions.id} onClick={openModelDeleteDocumentVersion} data-bs-toggle="modal" data-bs-target={`#confirmarModalDocVersiones${fileversions.id}`}>
                                                                        <i className="fa-solid fa-trash-can"></i> Eliminar
                                                                    </a>
                                                                </li>
                                                            </ul> 
                                                        </div>
                                                    </td>

                                                    {/* <!-- Modal Confirmar Eliminación --> */}
                                                    <div className="modal" id={`confirmarModalDocVersiones${fileversions.id}`} tabIndex="-1" role="dialog" aria-labelledby="confirmarModalDocLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel"> Eliminar Definitivamente el Documento</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                
                                                                <div className="modal-body text-center ">
                                                                <p>¿Estás seguro de que deseas eliminar <b>
                                                                    "{fileversions.nombre_documento_padre}{fileversions.version}.{fileversions.extension}"?
                                                                </b></p>
                                                                </div>

                                                                <div className="modal-footer d-flex justify-content-center">
                                                                    <button type="button" className="btn btn-gris" data-bs-dismiss="modal"><i className="fa-solid fa-ban"
                                                                    ></i> Cancelar </button>

                                                                    <button className="btn btn-danger" type="button" idDocumentVersionDelete={fileversions.id} onClick={onDeleteDocumentVersion} >
                                                                        <i className="fa-solid fa-trash-can"> </i> Eliminar
                                                                    </button>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- Fin Modal Confirmar Eliminación --> */}

                                                    {/* <!-- Modal Restaurar documento version --> */}
                                                    <div className="modal" id={`confirmarModalDocRestaurarVersion${fileversions.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">

                                                                <div className="modal-header">
                                                                    <h5 className="modal-title col-11 d-flex justify-content-center" id="exampleModalLabel">Restaurar Versión de Documento</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>

                                                                <div className="modal-body text-center">
                                                                    ¿Estás seguro de que quieres restaurar este documento?
                                                                </div>

                                                                <div className="modal-footer d-flex justify-content-center">
                                                                    <button type="button" className=" btn btn-gris" data-bs-dismiss="modal"><i className="fa-solid fa-ban" 
                                                                    > </i> Cancelar</button>
                                                                    <a href="#/" className="btn btn-morado"  idDocumentVersion={fileversions.id} onClick={onRestaurarDocumentVersion}><i className="fa-solid fa-trash-arrow-up"></i> Restaurar</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr>
                                            );
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </div>
        </>
    )
}
