import React from 'react'

export const Facturacion = () => {
    return (
        <div className="vista" id="vista-factura">
            {/* <!-- Modal Solicitar Licencias --> */}
            <div className="modal fade" id="comprarLicenciaModal" tabIndex="-1" role="dialog" aria-labelledby="comprarLicenciaModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="comprarLicenciaModalLabel">Solicitar Licencias</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {/* <!-- Agregar el formulario aquí --> */}
                            {/* <form method="POST" id="nuevo-equipo-form"> */}
                                {/* {% csrf_token %} */}
                                <h4>Agregar Licencia</h4>
                                <label for="licencias"><b>Número de Licencias: </b></label>
                                <input type="text" name="licencias" id="licencias" 
                                // style="width:110px;"
                                />
                                
                                <label for="totalLicencias"><b>Costo Total:</b></label>
                                <input type="text" name="totalLicencias" id="totalLicencias" readonly 
                                // style="width:130px;"
                                />
                                
                                <div className="d-grid gap-2">
                                    <button type="submit" id="crear-equipo" name="agregar_licencia" className="btn btn-modal">Solicitar</button>
                                </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Fin Modal Solicitar Licencias --> */}

            {/* <!-- Modal Solicitar Almacenamiento --> */}
            <div className="modal fade" id="comprarAlmacenamientoModal" tabindex="-1" role="dialog" aria-labelledby="comprarAlmacenamientoModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="comprarAlmacenamientoModalLabel">Solicitar Almacenamiento</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h5>Agregar Almacenamiento</h5>
                            <label for="almacenamiento"><b>Tamaño de almacenamiento:</b></label>
                            <input type="text" name="almacenamiento" id="almacenamiento" 
                            /> TB 
                            <label for="totalAlmcenamiento"><b>Costo Total:</b></label>
                            <input type="text" name="totalAlmcenamiento" id="totalAlmcenamiento" readonly />
                            
                            <div className="d-grid gap-2">
                                <button type="submit" id="crear-equipo" name="agregar_alamcenamiento" className="btn btn-modal">Solicitar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Fin Modal Solicitar Almacenamiento --> */}

            <div className="row ">
                <h1 className="row">Facturación</h1>
                <div className="row">
                    <h3 className="col">Detalles</h3>
                    <div className="col d-flex justify-content-end">
                        <div className="btn-group btn-comprar">
                            <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">  Comprar +  </button>

                            <ul className="dropdown-menu">
                                <li className="list-group-item active" aria-current="true">
                                    <button className="btn" data-toggle="modal" data-target="#comprarLicenciaModal">
                                        Licencias
                                    </button>
                                </li>
                                <li className="list-group-item active" aria-current="true">
                                    <button className="btn" data-toggle="modal" data-target="#comprarAlmacenamientoModal2">
                                        Almacenamiento
                                    </button>

                                    {/* <!-- Modal Solicitar Almacenamiento --> */}
                                    <div className="modal fade" id="comprarAlmacenamientoModal2" tabindex="-1" role="dialog" aria-labelledby="comprarAlmacenamientoModal2Label" aria-hidden="true">
                                        <div className="modal-dialog modal-md" role="document">
                                            <div className="modal-content">

                                                <div className="modal-header">
                                                    <h4 className="modal-title" id="comprarAlmacenamientoModal2Label">Solicitar Almacenamiento</h4>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>

                                                <div className="modal-body">
                                                    {/* <!-- Agregar el formulario aquí --> */}
                                                    {/* <form method="POST" id="nuevo-equipo-form"> */}
                                                        <h5>Agregar Almacenamiento</h5>
                                                        <label for="almacenamiento"><b>Tamaño de almacenamiento:</b></label>
                                                        <input type="text" name="almacenamiento" id="almacenamiento" 
                                                        // style="width:110px;"
                                                        /> TB 
                                                        <label for="totalAlmcenamiento"><b>Costo Total:</b></label>
                                                        <input type="text" name="totalAlmcenamiento" id="totalAlmcenamiento" readonly 
                                                        // style="width:130px;"
                                                        />
                                                        <div className="d-grid gap-2">
                                                            <button type="submit" id="crear-equipo" name="agregar_alamcenamiento" className="btn btn-modal">Solicitar</button>
                                                        </div>
                                                    {/* </form> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Fin Modal Solicitar Almacenamiento --> */}
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
                
                <div className = "verificación-pasos col-5 m-3">
                    <div className="m-3">
                        <div className="row">
                            <h4 className="col">Plan</h4>
                            <h4 className="col d-flex justify-content-end text-muted">Mensual</h4>
                        </div>
                        <h3 className="mt-4 d-flex justify-content-center">Hypercloud  <p className="text-muted fs-5 m-2">  $ 44 USD</p> </h3>
                        <h5 className="mt-5 text-muted d-flex justify-content-center">3 Licencias + 1 TB Almacenamiento</h5>
                    </div>
                </div>

                <div className = "verificación-pasos col-5 m-3">
                    <div className="m-3">
                        <div className="row">
                            <h4 className = "col">Compras extras</h4>
                            <div className="d-flex justify-content-end col">
                                <button className = "btn btn-dark mb-2" data-toggle="modal" data-target="#comprasExtrasModal">Historial Compras</button>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Descipcion</th>
                                    <th>Cant.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {% for compra in comprasExtrasRecientes %} */}
                                    <tr>
                                        <td>
                                            compra.id {/* {{compra.id}} */}
                                        </td>
                                        <td>
                                            compra.tipo {/* {{compra.tipo}} */}
                                        </td>
                                        <td>
                                            compra.cantidad {/* {{compra.cantidad}} */}
                                        </td>
                                    </tr>
                                {/* {% endfor %} */}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className = "verificación-pasos col-5 m-3">
                    <div className="m-3">
                        <div className="row">
                            <h4 className="col">Total Facturado</h4>
                            <h4 className="col d-flex justify-content-end text-muted">Mensual</h4>
                        </div>
                        <h6 className="text-muted mt-2">Plan Hypercloud</h6>
                        <h6 className="text-muted mt-3">Licencias Extras: 
                            {/* {{sumasLicencias}} */}
                        </h6>
                        <h6 className="text-muted mt-3">Alamcenamiento Extra: 
                            {/* {{sumasAlmacenamiento}} */}
                        </h6>
                        <h4 className="d-flex justify-content-end">
                            {/* $ {{sumaTotalMensual}}USD */}
                        </h4>
                    </div>
                </div>
            </div>

            {/* <!-- Modal Nuevo Paquete --> */}
            <div className="modal fade" id="comprasExtrasModal" tabindex="-1" role="dialog" aria-labelledby="comprasExtrasModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="comprasExtrasModalLabel">Historial Compras Extras</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Descipcion</th>
                                        <th>Cant.</th>
                                        <th>Fecha de Compra</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {% for compra in comprasExtras %} */}
                                        <tr>
                                            <td>
                                                compra .id
                                            </td>
                                            <td>
                                                compra .tipo
                                            </td>
                                            <td>
                                                compra .cantidad
                                            </td>
                                            <td>
                                                compra .fecha_de_compra
                                            </td>
                                        </tr>
                                    {/* {% endfor %} */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Fin Modal Nuevo Paquete --> */}
        </div>
    )
}
