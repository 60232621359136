import React, { useCallback, useEffect, useState } from 'react'
// import { Link } from 'react-router-dom';
import '../styles/login.css'
import { FooterLogin } from './FooterLogin';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../router/Auth';
import { useSelector, useDispatch } from 'react-redux';
// `

export const Login = () => {

    const [rememberMe, setRememberMe] = useState(false);

    const auth = useAuth();

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(null); // Para almacenar los datos de la respuesta

    const dispatch = useDispatch();

    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);

    const urlHostTenant = useSelector((state) => state.urlHost);


    const handleLogin = useCallback(async (e) => {
        if (rememberMe) {
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
        } else {
            localStorage.removeItem('email');
            localStorage.removeItem('password');
        }
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            if (!email.includes('@')) {
                throw new Error('Email no válido');
            }
            const domain = email.split('@')[1];
            const [secondLevelDomain, topLevelDomain] = domain.split('.');
            const xDtsSchema = `${secondLevelDomain}${topLevelDomain}`;

            const response = await axios.post(`${urlHostTenant}/users/auth/`, {
                username: email,
                password: password
            }, {
                headers: {
                'X-DTS-SCHEMA': xDtsSchema,
                'Content-Type': 'application/json'
                }
            });
            setSchemaTenant(xDtsSchema);

            
            dispatch(setSchemaTenant(xDtsSchema));
            // dispatch(setSchemaTenant(urlHostTenant));
            console.log('Respuesta de la API:', xDtsSchemaTenant);
            // Manejar la respuesta de la API según tu lógica
            auth.login(response.data.data);

            if (response.data.data.rol === 'editor') {
                if (response.data.data.nombreCompleto === '') {
                    navigate('/registrotrabajador');
                } else {
                    navigate('/welcomeuser');
                }
            } else {
                navigate('/welcomeadmi');
            }
            // Limpiar errores y actualizar estado
            //setInvalidPassword(false);
        }catch (error) {
        console.error('Error al intentar iniciar sesión:', error);
        //setLoginResponse({ error: 'Error al intentar iniciar sesión' });
        setPassword('');
        //setInvalidPassword(true);
    }
    }, [email, password,navigate,auth]);

    useEffect(() => {
        // Obtener email y password de localStorage si existen
        const savedEmail = localStorage.getItem('email');
        const savedPassword = localStorage.getItem('password');
        if (savedEmail) {
            setEmail(savedEmail);
            setRememberMe(true);
        }
        if (savedPassword) {
            setPassword(savedPassword);
            setRememberMe(true);
        }
    }, []);


    const setSchemaTenant = (xDtsSchema) => ({
        type: 'SET_SCHEMA_TENANT',
        payload: xDtsSchema,
    });


    return (
        <div className='body-login'>
            <div className='container-form d-flex align-items-center'>
                    {/* Modal */}
                <div className="modal fade" id="myModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {/* Cabecera del modal */}
                            <div className="modal-header">
                                <h4 className="modal-title">Correo Inexistente</h4>
                            </div>
                            
                            {/* Cuerpo del modal */}
                            <div className="modal-body">
                                {/* {{ error_correo|safe }}<!-- Esto muestra el mensaje de error --> */}
                                <p>Error, verifica tú correo</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* fin modal error guardar */}

                {/* <!-- Modal Olvidaste la contraseña --> */}
                <div className="modal" id="contrasena" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            
                            <div className="modal-header">
                                <h4 className="modal-title-olvidaste" id="contrasena-label">¿Olvidaste tú contraseña?</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            
                            <div className="modal-body">
                                {/* <form method="POST" id="nuevo-equipo-form"> */}
                                    <p className="text">Ingresa tú correo electrónico para recibir instrucciones de como reestablecer tú contraseña.</p>
                                    <input type="email" className='content-input' name="correo" id="correo" placeholder="Ingresa tú correo electrónico" />
                                    <button type="submit" name="cambio_contrasena" className="btn btn-modal-enviar text-center" htmlFor=''>Enviar</button>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Fin Modal Olvidaste tú contraseña --> */}

                <div className="img-fondo "></div>

                <div className="form-content">
                    <h1 className="title-hyper text-center">¡Bienvenido a Lyvian!</h1>
                    <h2 className="title-iniciar-sesion">Iniciar Sesión</h2>
                    <div>
                        <div className="input-contenedor">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" className="icono-user" >
                                <path d="M20 20C18.1667 20 16.5972 19.3472 15.2917 18.0417C13.9861 16.7361 13.3333 15.1667 13.3333 13.3333C13.3333 11.5 13.9861 9.93055 15.2917 8.62499C16.5972 7.31943 18.1667 6.66666 20 6.66666C21.8333 6.66666 23.4028 7.31943 24.7083 8.62499C26.0139 9.93055 26.6667 11.5 26.6667 13.3333C26.6667 15.1667 26.0139 16.7361 24.7083 18.0417C23.4028 19.3472 21.8333 20 20 20ZM6.66666 33.3333V28.6667C6.66666 27.7222 6.91 26.8539 7.39666 26.0617C7.88333 25.2694 8.52889 24.6655 9.33333 24.25C11.0556 23.3889 12.8056 22.7428 14.5833 22.3117C16.3611 21.8805 18.1667 21.6655 20 21.6667C21.8333 21.6667 23.6389 21.8822 25.4167 22.3133C27.1944 22.7444 28.9444 23.39 30.6667 24.25C31.4722 24.6667 32.1183 25.2711 32.605 26.0633C33.0917 26.8555 33.3344 27.7233 33.3333 28.6667V33.3333H6.66666Z" fill="#54388D"/>
                                </svg>
                            <input type="text" name="username" id="username"  value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Correo electrónico / Usuario" />
                        </div>
                    </div>

                    {/* {showPasswordInput && ( */}
                    <div>
                        <div className="input-contenedor">
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 35 30" fill="none" className="icono-candado">
                                <path d="M8.75 27.5C7.94791 27.5 7.26104 27.255 6.68937 26.765C6.11771 26.275 5.83236 25.6867 5.83333 25V12.5C5.83333 11.8125 6.11916 11.2238 6.69083 10.7338C7.2625 10.2438 7.94889 9.99917 8.75 10H10.2083V7.5C10.2083 5.77083 10.9195 4.29667 12.3419 3.0775C13.7642 1.85833 15.4836 1.24917 17.5 1.25C19.5174 1.25 21.2372 1.85958 22.6596 3.07875C24.0819 4.29792 24.7926 5.77167 24.7917 7.5V10H26.25C27.0521 10 27.739 10.245 28.3106 10.735C28.8823 11.225 29.1676 11.8133 29.1667 12.5V25C29.1667 25.6875 28.8808 26.2763 28.3092 26.7663C27.7375 27.2563 27.0511 27.5008 26.25 27.5H8.75ZM17.5 21.25C18.3021 21.25 18.989 21.005 19.5606 20.515C20.1323 20.025 20.4176 19.4367 20.4167 18.75C20.4167 18.0625 20.1308 17.4738 19.5592 16.9838C18.9875 16.4938 18.3011 16.2492 17.5 16.25C16.6979 16.25 16.011 16.495 15.4394 16.985C14.8677 17.475 14.5824 18.0633 14.5833 18.75C14.5833 19.4375 14.8692 20.0263 15.4408 20.5163C16.0125 21.0063 16.6989 21.2508 17.5 21.25ZM13.125 10H21.875V7.5C21.875 6.45833 21.4497 5.57292 20.599 4.84375C19.7483 4.11458 18.7153 3.75 17.5 3.75C16.2847 3.75 15.2517 4.11458 14.401 4.84375C13.5503 5.57292 13.125 6.45833 13.125 7.5V10Z" fill="#54388D"/>
                            </svg>
                            <input type="password" name="password" id="password" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        {/* <h6 style={{ color: 'red' }}>Contraseña inválida</h6>
                        <p style={{ color: 'red' }}>Corrobora que tus accesos email/contraseña sean correctos</p> */}
                        <button className="button" onClick={handleLogin}>Iniciar Sesión</button>
                    </div>
                    {/* )}  */}
                    {/* Checkbox que permite recordar contraseña y usuario */}
                    <div className="contenedor-textos">
                        <span id="recuerdame d-inline-flex align-items-center">
                            <input type="checkbox" id="checkpoint" className='me-2' checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}/>
                            <label htmlFor="checkpoint" >Recuérdame</label>
                        </span>
                        
                        <span id="olvidaste-pass text-decoration-none ">
                            <a href='#/' className='btn' id="modal-contrasena" data-bs-toggle="modal" data-bs-target="#contrasena">
                                ¿Olvidaste tú Contraseña?
                            </a>
                        </span>
                    </div>
                    {/* <button onClick={handleLogout}>Logout</button> */}
                </div>
                
                
                <FooterLogin />
            </div>
        </div>
    )
}
