import React from 'react'

export const Footer = () => {
    return (
        // Footer
        <footer className="footer">
            <div className="item-footer">
            <b>Asistencia Técnica</b>
            </div>
            <div className="item-footer">
            <i className="fa-solid fa-phone-volume"></i>
            <b>+55 8993 8993</b>
            </div>
            <div className="item-footer">
            <i className="fa-sharp fa-regular fa-envelope"></i>
            <b>soporte@hyperox.biz</b>
            </div>
        </footer>
        // Fin Footer
    )
}
