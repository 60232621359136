import React, { useCallback, useEffect, useState } from 'react'
import './styles/register_user.css'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../router/Auth';
import axios from "axios";
import { useSelector } from 'react-redux';


export const RegistroTrabajador = () => {
    const {user,login} = useAuth();

    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema); 

    const navigate = useNavigate();

    const [fullname, setFullName] = useState('')
    const [area, setArea] = useState('')
    const [puesto, setPuesto] = useState('')
    const [celular, setCelular] = useState('')
    const [telefono, setTelefono] = useState('')
    // const [correo, setCorreo] = useState('')
    const [newPassword,setNewPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const [image, setImage] = useState('')

    useEffect(() => {
        document.title = 'Registro Trabajador - Lyvian';
        return () => {
            document.title = 'Título Predeterminado';
        };
    }, []);

    const onSubmit = useCallback (async (event) => {
        event.preventDefault();

        // Verificar si todos los campos del formulario están completos
        if (
            fullname.trim() === '' ||
            area.trim() === '' ||
            puesto.trim() === '' ||
            celular.trim() === '' ||
            telefono.trim() === '' ||
            // correo.trim() === '' ||
            newPassword.trim() === '' ||
            confirmPassword.trim() === '' 
        ) {
            // Si algún campo está vacío, mostrar alerta al usuario
            alert('Por favor completa todos los campos del formulario.');
            return;
        }

        try {
            console.log("registro trabajador")
            
            if( newPassword === confirmPassword){
                await axios.post(`${urlHostTenant}/users/workers/`, {
                    "nombreCompleto": fullname,
                    "area": area,
                    "usuario_id": user.usuario_id,
                    "puesto": puesto,
                    "celular": celular,
                    "telefono": telefono,
                    "email": user.email,
                    "contrasena": newPassword,
                    "imagen": 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Icecat1-300x300.svg/1024px-Icecat1-300x300.svg.png',
                }, 
                {   
                headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                }).then(({data}) => { 
                    login(data.data)
                })
                
                alert("¡Registro exitoso!");

                navigate('/welcomeuser');


            }else{
                alert('Error, contraseña inválida')
            }
            
        } catch (error){
            console.log('Error', error) //ilustracion para el usuario
            alert('Ocurrió un error durante el registro. Por favor inténtalo nuevamente.', error);
        }finally{
            // registrarlo y redireccionarlo al login
        }
    }, [fullname, area, puesto, celular, telefono, newPassword, confirmPassword, navigate,user,login]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
    };

    if(user.nombreCompleto != ''){
        navigate('/welcomeuser');
    }

    return (
        <div className="body-registrouser justify-content-center">
            {/* <!-- Aquí colocamos el formulario --> */}
            <div className="">

                <form className="formulario-inicio" method="POST" encType="multipart/form-data" id="form-styles">
                    <h1>¡Únete a Lyvian!</h1>

                    <p className="titulo-registrate mb-2">Registrate</p>

                    <p className="texto m-0">Ingresa la información para registrar tu cuenta</p>

                    <div className="row">
                        <div className="col-6 mt-3">
                            <input type="text" className='mb-3' name="fullname" onChange={(e) => setFullName(e.target.value)} placeholder="Nombre Completo"/>

                            <select className="form-select mb-3" onChange={(e) => setArea(e.target.value)} aria-label="Default select example">
                                <option selected>Área</option>
                                <option value="1">Finanzas</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>

                            {/* <label >Celular</label> */}
                            <input type="text" className='mb-3' name="celular" onChange={(e) => setCelular(e.target.value)}placeholder="Celular"/>
                        </div>

                        <div className="col-6 mt-3">
                            {/* <input type="text" className='mb-3' name="correoelectronico" onChange={(e) => setCorreo(e.target.value)} placeholder="Correo Electrónico"/> */}

                            <input type="text" className='mb-2' name="puesto" onChange={(e) => setPuesto(e.target.value)} placeholder="Puesto"/>

                            <input type="text" className='mb-3' name="telefono" onChange={(e) => setTelefono(e.target.value)} placeholder="Telefono"/>  
                        </div>

                        <div className="col-6">
                            <input type="password" className='mb-3' name="nueva contraseña" onChange={(e) => setNewPassword(e.target.value)}placeholder="Nueva Contraseña"/>
                        </div>
                                            
                        <div className="col-6">
                            <input type="password" name="confirmarcontrasena" onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirmar Contraseña"/>
                        </div>

                        <div className="col-12">
                            <input className="form-control" type="file" id="inputFile" onChange={handleFileChange} />
                        </div>
                        

                        {/* <!-- Checkbox de Términos y Condiciones--> */}
                        <div className="terminos mt-4">
                            <input type="checkbox" name="checkpoint" id="inputcheck" />
                            <p className="terminos-link">Al hacer click estarás aceptando las Políticas de la empresa, así como los Términos y Condiciones.</p>
                        </div>

                        <div className="d-grid gap-2 mt-4">
                            <button type="submit" name = "registar_trabajador" onClick={onSubmit} className="btn btn-modal">Registra tú cuenta</button>
                        </div><br/> 
                    </div>   
                </form>
            </div>
        </div>
    )
}
