import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useRecentFolder = (user = null, idFather = null, nombreFather = null) => {
  const [recentFolders, setRecentFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
  const urlHostTenant = useSelector((state) => state.urlHost);
  // Este va para los headers
  const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');

      try {
        var response = {};
        if(idFather===null){ // Estoy en home
          
          if (user.rol === "admin") {
            // Es un usuario administrador
            response = await axios.get(`${urlHostTenant}/folders/recentFolder?usuario=${user.username}`,
              {headers: {
                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                'X-DTS-SCHEMA': xDtsSchemaTenant,
            }}
            );
            // http://hyperoxbiz.localhost:8000/folders/recentFolder/
          } else {
            // worker
            response = await axios.get(`${urlHostTenant}/folders/recentFolder?usuario=${user.nombreCompleto}`,
              {headers: {
                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                'X-DTS-SCHEMA': xDtsSchemaTenant,
            }}
            );
          }
        }else{ // Estoy ensubcarpeta

          if(user.rol === "admin") {
            response = await axios.get(`${urlHostTenant}/folders/recentFolder?usuario=${user.username}`,
              {headers: {
                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                'X-DTS-SCHEMA': xDtsSchemaTenant,
            }}
            );

          }else{//worker
            response = await axios.get(`${urlHostTenant}/folders/recentFolder?usuario=${user.nombreCompleto}`,
              {headers: {
                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                'X-DTS-SCHEMA': xDtsSchemaTenant,
            }}
            );
          }

        }

        console.log('folders', response)
        setRecentFolders(response.data.data);
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetchData();
  }, [user, idFather, nombreFather]);

  return { recentFolders, loading, error };
};

export default useRecentFolder;