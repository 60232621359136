import React from 'react';
import useTeams from '../../hooks/useTeams';

export const VistaUserEquipos = () => {
  const { teams, loading, error } = useTeams();

  return (
    <div className="vista-user-equipos d-flex">
      {teams.map((team) => (
        <div className="card equipos-card mt-2 me-5" key={team.id}>
          <div className="d-flex justify-content-end cursor puntitos-equipos">
            <i id="myCheckbox" data-bs-toggle="modal" data-bs-target="#equipoModal" className="fa-solid fa-ellipsis-vertical"></i>
          </div>

          <img src={team.imagen} id="img-equipo" className='d-flex' alt={''} />

          <div className="card-body">
            <h5>
              <a className='m-0 nombre-equipo text-decoration-none' href="#/">
                {team.nombre_equipo}
              </a>
            </h5>
            <p className="card-text"><small className="text-muted">
              {team.trabajadores} integrantes
            </small></p>

            <div className="storage-equipo">
              <div className="progress">
                <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div className="space-info d-flex justify-content-between">
                <div>0</div>
                <div>50GB</div>
                <div>1TB</div>   
              </div>
            </div>

            <a href="/contenidocarpetaadmi" className="d-flex justify-content-end detalles-equipos text-decoration-none"><b>Ver archivos</b></a>
          </div>
        </div>
      ))}
    </div>
  );
};
