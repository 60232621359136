import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useShareFiles = (user=null, idFather=null) => {
    const [filesSharedForMe, setFilesSharedForMe] = useState([]);
    const [filesSharedByMe, setFilesSharedByMe] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    
    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError('');

            try {
                var response = {}

                if(idFather==null){ // Estoy en home
                    if (user.rol === "admin") {
                        response = await axios.get(`${urlHostTenant}/shared/shareDocument?usuario=${user.username}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        );
                        // http://hyperoxbiz.localhost:8000/shared/shareDocument/
                    }else{
                        response = await axios.get(`${urlHostTenant}/shared/shareDocument?usuario=${user.nombreCompleto}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        );
                    }
                }else{ // Estoy en subcarpeta
                    if(user.rol === "admin") {
                        response = await axios.get(`${urlHostTenant}/shared/shareDocument?nameUser=${idFather}&usuario=${user.username}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        );
                    }else{
                        response = await axios.get(`${urlHostTenant}/shared/shareDocument?nameUser=${idFather}&usuario=${user.nombreCompleto}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        );
                    }
                }

                console.log('sharePorMiDOCUMENTOS', response.data.data['documentos_compartidos_por_mi'])
                setFilesSharedByMe(response.data.data['documentos_compartidos_por_mi']);
                console.log('shareConmigoDOCUMENTOS', response.data.data['documentos_compartidos_para_mi'])
                setFilesSharedForMe(response.data.data['documentos_compartidos_para_mi']);
            } catch (error) {
                setError(error);
            }

            setLoading(false);
        };
    
        fetchData();
    }, [user, idFather]);

    return { filesSharedForMe,filesSharedByMe, loading, error};
    };
export default useShareFiles;