import { createStore } from 'redux';

// Obtener el estado desde el almacenamiento local
const persistedState = JSON.parse(localStorage.getItem('reduxState')) || {};

// Definir las variables de entorno con valores por defecto si no se establecen
// src/config.js o donde necesites estas variables
const dbHost = process.env.REACT_APP_DB_HOST || '187.251.130.92'; // Valor por defecto
const dbPort = process.env.REACT_APP_DB_PORT || '8000'; // Valor por defecto

const urlHost = `http://${dbHost}:${dbPort}`;

// Para verificar si las variables se están asignando correctamente
console.log('DB Host:', dbHost); // Debe imprimir el valor de REACT_APP_DB_HOST
console.log('DB Port:', dbPort); // Debe imprimir el valor de REACT_APP_DB_PORT
console.log('URL Host:', urlHost); // Debe mostrar la URL construida


// Definir el estado inicial
const initialState = {
    tenantDjangoUrl: persistedState.tenantDjangoUrl || '',
    adminDomain: persistedState.adminDomain || '',
    xDtsSchema: persistedState.xDtsSchema || '',
    urlHost: urlHost,
};

// Definir los reducers
function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_SCHEMA_TENANT':
            return {
                ...state,
                xDtsSchema: action.payload,
            };
        case 'SET_ADMIN_DOMAIN':
            return {
                ...state,
                adminDomain: action.payload,
            };
        case 'SET_URL_HOST': // Asegúrate de que este sea el tipo de acción correcto
            return {
                ...state,
                urlHost: action.payload,
            };
        case 'LOGOUT':
            return {
                ...state,
                tenantDjangoUrl: '',
                adminDomain: '',
                empresa: '',
                xDtsSchema: '',
            };
        default:
            return state;
    }
}

// Crear el store
const store = createStore(rootReducer);

// Suscribirse a los cambios en el store y guardar el estado en el almacenamiento local
store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

export default store;
