import React from 'react';
import useUsers from '../../hooks/useUsers'

export const VistaAdmiUsers = () => {

  const { workers, loading, error } = useUsers();

  console.log("Workers:", workers);

  return (
    <div className="vista-admin-usuarios d-flex">
      {workers.map((worker) => (
        worker.nombreCompleto !== '' && 
        <div className="carpetas-usuarios" key={worker.id}> 
          <div className="content-folder-usuarios nombre-carpeta-div me-5">
            <img src={worker.imagen} className='img-usuarios' alt={''} />
            <div className="info-folder-trabajador">
              <div id="folder-name" className="option-link nombre-carpeta d-flex justify-content-center">
                <a  className="puntitos">
                  {worker.nombreCompleto}
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};