import React, { useState, useEffect } from 'react';

const Buscador = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [visibleItems, setVisibleItems] = useState([]);

    useEffect(() => {
        const carpetasDivs = document.querySelectorAll(".nombre");
        const filasTabla = document.querySelectorAll("tbody tr");

        const handleSearch = () => {
            const searchTermLower = searchTerm.toLowerCase();

            // Buscar en carpetasDivs
            carpetasDivs.forEach(carpetaDiv => {
                const carpetaNombre = carpetaDiv.querySelector(".nombre-carpeta").textContent.toLowerCase();
                if (carpetaNombre.includes(searchTermLower)) {
                    carpetaDiv.parentElement.style.display = "block";
                } else {
                    carpetaDiv.parentElement.style.display = "none";
                }
            });

            // Buscar en filasTabla
            filasTabla.forEach(fila => {
                const celdas = fila.querySelectorAll("td");
                let encontradoEnTabla = false;

                celdas.forEach(celda => {
                    const textoCelda = celda.textContent.toLowerCase();
                    if (textoCelda.includes(searchTermLower)) {
                        encontradoEnTabla = true;
                    }
                });

                if (encontradoEnTabla) {
                    fila.style.display = "";
                } else {
                    fila.style.display = "none";
                }
            });
        };

        handleSearch();

        return () => {
            // Limpiar al desmontar el componente
            carpetasDivs.forEach(carpetaDiv => {
                carpetaDiv.parentElement.style.display = "";
            });

            filasTabla.forEach(fila => {
                fila.style.display = "";
            });
        };
    }, [searchTerm]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="Buscador" role="search">
            <input
                className="form-control"
                type="search"
                id="buscador"
                placeholder="Buscar..."
                aria-label="Search"
                value={searchTerm}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default Buscador;
