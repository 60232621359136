import React from 'react'
import { NavLink } from 'react-router-dom'

export const SidebarPanel = () => {
    return (
        <div className="sidebar">
            {/* <!-- Sidebar con las opciones del menú --> */}
            {/* <!-- Nombre superior del sidebar --> */}
            <div className="name__page pt-3 pb-2">
                <h3>Panel</h3>
            </div>

            {/* <!-- Componentes dentro del sidebar --> */}
            <div className="options__menu ">
                {/* Dashboard */}
                <NavLink to='/paneladmi' className={({isActive}) => isActive ? 'active' : ''}>
                    <div className="option move-menu">
                        <div className="icon__menu">
                            <i className="fa-solid fa-house"></i>
                        </div>
                        <div>
                            <span className="option-link ">Dashboard</span>
                        </div>
                    </div>
                </NavLink>

                {/* Usuarios */}
                <NavLink to='/paneladmi/usuariospanel' className={({isActive}) => isActive ? 'active' : ''}>
                    <div className="option move-menu">
                        <div className="icon__menu">
                        <i className="fa-solid fa-user-group"></i>
                        </div>
                        <div>
                            <span className="option-link">Usuarios</span>
                        </div>
                    </div>
                </NavLink>
                
                {/* Equipos */}
                <NavLink to='/paneladmi/equipospanel' className={({isActive}) => isActive ? 'active' : ''}>
                    <div className="option move-menu">
                        <div className="icon__menu">
                            <i className="fa-solid fa-users"></i>
                        </div>
                        <div>
                            <span className="option-link">Equipos</span>
                        </div>
                    </div>
                </NavLink>
                
                {/* Backup */}
                <NavLink to='/paneladmi/backuppanel' className={({isActive}) => isActive ? 'active' : ''}>
                    <div className="option move-menu">
                        <div className="icon__menu">
                            <i className="fa-solid fa-cloud-arrow-up"></i>
                        </div>
                        <div>
                            <span className="option-link">Backup</span>
                        </div>
                    </div>
                </NavLink>

                {/* Facturación */}
                <NavLink to='/paneladmi/facturacionpanel' className={({isActive}) => isActive ? 'active' : ''}>
                    <div className="option move-menu">
                        <div className="icon__menu">
                            <i className="fa-solid fa-file-invoice-dollar"></i>
                        </div>
                        <div>
                            <span className="option-link">Facturación</span>
                        </div>
                    </div>
                </NavLink>
                
                {/* Políticas */}
                <NavLink to='/paneladmi/politicaspanel' className={({isActive}) => isActive ? 'active' : ''}>
                    <div className="option move-menu">
                        <div className="icon__menu">
                            <i className="fa-solid fa-file-shield"></i>
                        </div>
                        <div>
                            <span className="option-link">Políticas</span>
                        </div>
                    </div>
                </NavLink>
            </div>

            {/* <!-- Sección de Solicitar Almacenamiento --> */}
            <div className="storage mt-3 mb-3 p-3">
                <p className="text-center title-almacenamiento fs-6"><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                    <path d="M4.33317 12.312C3.32206 12.312 2.45806 12.0037 1.74117 11.3872C1.02428 10.7707 0.66606 10.0172 0.666504 9.12666C0.666504 8.36335 0.927615 7.68323 1.44984 7.08629C1.97206 6.48935 2.65539 6.10769 3.49984 5.94133C3.77762 5.04103 4.33317 4.31198 5.1665 3.75418C5.99984 3.19638 6.94428 2.91748 7.99984 2.91748C9.29984 2.91748 10.4027 3.31636 11.3085 4.1141C12.2143 4.91185 12.6669 5.88301 12.6665 7.02757C13.4332 7.10586 14.0694 7.39709 14.5752 7.90126C15.0809 8.40543 15.3336 8.99494 15.3332 9.66977C15.3332 10.4037 15.0414 11.0277 14.4578 11.5416C13.8743 12.0556 13.1661 12.3124 12.3332 12.312H4.33317ZM4.33317 11.1377H12.3332C12.7998 11.1377 13.1943 10.9958 13.5165 10.712C13.8387 10.4282 13.9998 10.0808 13.9998 9.66977C13.9998 9.25877 13.8387 8.91137 13.5165 8.62757C13.1943 8.34378 12.7998 8.20188 12.3332 8.20188H11.3332V7.02757C11.3332 6.21534 11.0081 5.52289 10.3578 4.95021C9.70762 4.37754 8.92162 4.0914 7.99984 4.09179C7.07762 4.09179 6.29139 4.37813 5.64117 4.9508C4.99095 5.52347 4.66606 6.21573 4.6665 7.02757H4.33317C3.68873 7.02757 3.13873 7.22818 2.68317 7.62941C2.22762 8.03063 1.99984 8.51503 1.99984 9.08262C1.99984 9.6502 2.22762 10.1346 2.68317 10.5358C3.13873 10.9371 3.68873 11.1377 4.33317 11.1377Z" fill="black"/>
                </svg> Almacenamiento Utilizado</b></p>
                <div className="progress">
                    <div className="progress-bar" role="progressbar" 
                    // style="width: {{ espacio_utilizado_percent }}%; background-color: purple;" aria-valuenow="{{ espacio_utilizado_percent }}" 
                    aria-valuemin="0" aria-valuemax="100">
                        {/* {{ espacio_utilizado_percent | floatformat:2 }}% */}
                    </div>
                </div>
                <div className="space-info text-center mt-2">
                    {/* {{ total_size_gb | floatformat:2 }} GB usados de {{ limite_espacio }} TB */}
                </div>
                <div>
                    <button className = "fw-semibold btn btn-modal mt-3" data-bs-toggle="modal" data-bs-target="#comprarAlmacenamientoModal">Solicitar Almacenamiento</button>

                    {/* <!-- Modal Solicitar Almacenamiento --> */}
                    <div className="modal fade" id="comprarAlmacenamientoModal" tabIndex="-1" role="dialog" aria-labelledby="comprarAlmacenamientoModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-md" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title col-11 d-flex justify-content-center" id="comprarAlmacenamientoModalLabel">Solicitar Almacenamiento</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>

                                <div className="modal-body">
                                    {/* <!-- Agregar el formulario aquí --> */}
                                    <form method="POST" id="nuevo-equipo-form">
                                        <div className='mb-3'>
                                            <label htmlFor="almacenamiento" className='col-form-label titulo-label'> Especifica el Tamaño</label>

                                            <input type="text" name="almacenamiento" id="almacenamiento" className='form-control content-input' />
                                        </div>
                                        
                                        <div className='mb-3'>
                                            <label htmlFor="totalAlmcenamiento" className='titulo-label'>Costo Total</label>

                                            <input className='form-control content-input' type="text" name="totalAlmcenamiento" id="totalAlmcenamiento" readOnly/>
                                        </div>
                                    </form>
                                </div>

                                <div className="modal-footer d-flex justify-content-center mb-3">
                                    <button type="submit" name="agregar_alamcenamiento" className="btn btn-morado">Solicitar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fin Modal Solicitar Almacenamiento --> */}
                </div>
            </div>        

        </div>
    )
}
