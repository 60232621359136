import React, { useCallback, useEffect ,useState} from 'react'

import { es } from 'date-fns/locale';
import imagenfolder from '../assets/folder.png';
import axios from "axios";
import { useAuth } from '../../router/Auth';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useFolders from '../../hooks/useFolders';
import useFiles from '../../hooks/useFiles';
import useVersions from '../../hooks/useVersionsFile';
import useUsers from '../../hooks/useUsers';
import DocumentModal from '../shared/DocumentModal';
import { useSelector, useDispatch } from 'react-redux';

export const WelcomeAdmi = () => {
    const { format } = require('date-fns');

    const {user} = useAuth();

    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);
    // este va en los endpoints
    const urlHostTenant = useSelector((state) => state.urlHost);

    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);

    const {folders, setIsLoadingFolder, error} = useFolders(user, null, null)
    const {files, setIsLoading: filesIsLoading} = useFiles(user, null)

    const {workers, loading: workersIsLoading} = useUsers()
    
    // Eliminar
    const [toDeleteID, setToDeleteId] = useState([]) // eliminar carpeta desde la modal en los tres puntitos
    const [toDeleteFileID, setToDeleteFileId] = useState([]) // eliminar documento desde la modal en los tres puntitos
    const [toDeleteFileVersionID, setToDeleteFileVersionId] = useState([]) // eliminar documento desde la modal de versiones

    // Versiones
    const {versions, setIdFile} = useVersions()
    const [versionToUpload, setVersionToUpload] = useState(null)
    const [versionDescripcion,setVersionDescripcion] = useState('');

    const [idfileOpen, setIdFileOpen] = useState("") // versiones
    const [namefileOpen, setnamefileOpen] = useState("") // versiones
    
    // Favoritos
    const [favoritesFolders, setFavoritesFolders] = useState([]); // favoritos
    const [favorites, setFavorites] = useState([]); // favoritos documentos

    // Compartidos
    const [destinatary, setDestinatary] = useState("") 
    const [destinataryFolder, setDestinataryFolder] = useState("") 

    // visualizacion   
    const [showModalVisualization, setShowModalVisualization] = useState(false);
    const [urlToVisualize, setUrlToVisualize] = useState('');


    const location = useLocation()
    // const {id: idFather} = useParams()
    const params = new URLSearchParams(location.search);
    // const nombreEquipo = params.get('equipo')

    const idFolders = params.get('idFolders')

    const [errores, setErrores] = useState('');
    const [success, setSuccess] = useState('');

    const navigate = useNavigate();



    useEffect(() => {
        document.title = 'Bienvenida - Lyvian'; // Cambia el título de la página cuando el componente se monta

        if(folders){
            const favoritosFoldersIds = folders.filter(folder => folder.favorito).map(folder => folder.id);
            setFavoritesFolders(favoritosFoldersIds);
        }

        if(files){
            const favoritosIds = files.filter(file => file.favorito).map(file => file.id);
            setFavorites(favoritosIds);
        }
    }, [folders, files]);

    // ---------------------------------------------------

    // Eliminar Carpetas desde la modal
    const onDelete = useCallback (async () => {
        try {
            await axios.delete(`${urlHostTenant}/folders/subfolder/${toDeleteID}`, { }, 
            {
                headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate,toDeleteID]);

    // Modal para Eliminar una Carpeta
    const openModelDelete = (event) => {
        event.preventDefault()
        const id_subfolder= event.currentTarget.getAttribute('id_subfolder')
        console.log("idsub",id_subfolder)
        setToDeleteId(id_subfolder)
    }

    // Eliminar Documentos desde la modal
    const onDeleteFile = useCallback (async () => {
        try {
            await axios.delete(`${urlHostTenant}/documents/document/${toDeleteFileID}`, { }, 
            {
                headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate,toDeleteFileID]);

    // Modal para Eliminar un Documento
    const openModelDeleteFile = (event) => {
        event.preventDefault()
        const idfile= event.currentTarget.getAttribute('idfile')
        console.log("idfile",idfile)
        setToDeleteFileId(idfile)
    }

    // Eliminar Documentos Version desde la modal
    const onDeleteFileVersion = useCallback (async () => {
        try {
            await axios.delete(`${urlHostTenant}/documents/documentVersion/${toDeleteFileVersionID}`, { }, 
            {
                headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate,toDeleteFileVersionID]);

    // Modal para Eliminar un Documento Version
    const openModelDeleteFileVersions = (event) => {
        event.preventDefault()
        const idVersion = event.currentTarget.getAttribute('idVersion');
        setToDeleteFileVersionId(idVersion);
    }
    // ----------------------------------------------

    // Enviar una version del documetno
    const onSendVersion= useCallback (async () => {
        setErrores('');
        setSuccess('');
        try {
            let formData = new FormData();
            formData.append('carpeta_id', user.username);
            formData.append('usuario_id', user.id);
            formData.append('archivo', versionToUpload);
            formData.append('documentoPadre_id', idfileOpen);
            formData.append('nombre_documento_padre', namefileOpen);
            formData.append('descripcion', versionDescripcion);
            console.log("idf",idfileOpen,namefileOpen)

            await axios.post(`${urlHostTenant}/documents/documentVersion/`, formData, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
            });
            setSuccess('¡Subido exitosamente!')
            navigate(0)
        } catch (error){
            console.log('Error', error.response.data.data) 
            setErrores(error.response.data.data)
            return false;
        }
    }, [versionToUpload,user,idfileOpen,namefileOpen,navigate,versionDescripcion]);

    // ---------------------------------------------

    // Favoritos Carpetas
    const handleFavoriteFoldersToggle = async (event, carpeta_id) => {
        const isChecked = event.target.checked;

        try {
            if (isChecked) {
                setFavoritesFolders([...favoritesFolders, carpeta_id]);

                // Si el folder se marca como favorito, enviar al backend
                await axios.post(`${urlHostTenant}/folders/favoritefolder/`, { carpeta_id, customuser_id : user.id,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                        },
                });
                console.log('documentidfolder:', carpeta_id)
                console.log('Carpeta checkeada:', carpeta_id)
                
            } else {
                setFavoritesFolders(favoritesFolders.filter(id => id !== carpeta_id));
                
                // Si el folder se desmarca como favorito, enviar al backend
                await axios.delete(`${urlHostTenant}/folders/favoritefolder/?carpeta_id=${carpeta_id}&customuser_id=${user.id}`,
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }},
                );
                
                console.log('documentid:', carpeta_id)
                console.log('Carpeta delete fav:', carpeta_id)
            }
        } catch (error) {
            console.error('Error al actualizar favorito:', error);
        }
    };

    // Favoritos Documentos
    const handleFavoriteToggle = async (event, documento_id) => {
        const isChecked = event.target.checked;

        try {
            if (isChecked) {
                setFavorites([...favorites, documento_id]);

                // Si el documento se marca como favorito, enviar al backend
                await axios.post(`${urlHostTenant}/documents/favoritedocuments/`, { documento_id, customuser_id : user.id, 
                    headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                });
                console.log('documentid:', documento_id)
                
            } else {
                setFavorites(favorites.filter(id => id !== documento_id));
                
                // Si el documento se desmarca como favorito, enviar al backend
                await axios.delete(`${urlHostTenant}/documents/favoritedocuments/?documento_id=${documento_id}&customuser_id=${user.id}`,
                    {
                        headers: {
                            Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                            'X-DTS-SCHEMA': xDtsSchemaTenant,
                        },
                    }
                );
                console.log('documentid:', documento_id)
            }
        } catch (error) {
            console.error('Error al actualizar favorito:', error);
        }
    };

    const onShareFolder = useCallback (async (event) => {
        try {
    
            event.preventDefault()
            const idFolder= event.currentTarget.getAttribute('idFolder')
            const nameFolder= event.currentTarget.getAttribute('nameFolder')
            await axios.post(`${urlHostTenant}/shared/shareFolder/`, {

                carpeta_id : idFolder,
                nombre_carpeta : nameFolder,
                remitente : user.username,
                destinatario : destinataryFolder
            }, 
            {
                headers: {
                    Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                    'X-DTS-SCHEMA': xDtsSchemaTenant,
                },
            })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate, destinataryFolder, user]);

    const onShareFile = useCallback (async (event) => {
        try {

            event.preventDefault()
            const idfile= event.currentTarget.getAttribute('idfile')
            const namefile= event.currentTarget.getAttribute('namefile')
            await axios.post(`${urlHostTenant}/shared/shareDocument/`, {
                
                documento_id : idfile,
                nombre_documento : namefile,
                remitente : user.username,
                destinatario : destinatary
            }, 
            {
                headers: {
                    Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                },
            })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate,destinatary,user]);

    // -------------------------------------------------

    // const handleDestinataryFolderChange = (e) => {
    //     setDestinataryFolder(e.target.value);
    // };

    // Modal Versiones
    const openModalVersions = (event) =>{
        event.preventDefault()
        const idFile= event.currentTarget.getAttribute('idFile')
        const namefileOpen= event.currentTarget.getAttribute('nameFile')
        setIdFile(idFile)
        setIdFileOpen(idFile)
        setnamefileOpen(namefileOpen)
    }

    const handleArchivoVersions = (e) => {
        const archivo = e.target.files;
        console.log("archivo",archivo)
        setVersionToUpload(archivo[0])
    };

    const handleOpenModalVisualization = (event) => {
        const urlFile= event.currentTarget.getAttribute('urlFile')
        setUrlToVisualize(urlFile)
        setShowModalVisualization(true);

        console.log("valor url",urlFile)
    };
    
    
    const handleCloseModalVisualization = () => {
    setShowModalVisualization(false);
    };

    console.log(urlHostTenant)
    
    return (
        <>
            <div className="vista">  
                <h1 className="titulo">Bienvenido {xDtsSchemaTenant}</h1>
                {/* <!-- Inicio carpetas en TABLA --> */}
                <div className="oculto" id="carpetas-filtradas">
                    <div className="carpetas" id="carpets">
                        <table className="table-hover">
                            <thead>
                                <tr className="fila-tabla">
                                    <th className="encabezado-tabla"></th>
                                    <th className="encabezado-tabla">Nombre </th>
                                    {/* <th className="encabezado-tabla">Tamaño</th> */}
                                    <th className="encabezado-tabla">Ubicación</th>
                                    <th className="encabezado-tabla">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    folders.map((folder,index) => (
                                        <tr className="fila-tabla" key={index}>

                                            {/* <!-- Imagen de la Carpeta --> */}
                                            <td className="imagen">
                                                <a href={'welcomeadmi/' + folder.id + '?idFolders=' + folder.id + '&parent='+folder.nombre_carpeta } className="option-link d-flex justify-content-center">
                                                    <img src={imagenfolder}id="img-folder" alt="Imagen de una Carpeta" />
                                                </a>
                                            </td>
                                            
                                            {/* <!-- Nombre de la Carpeta --> */}
                                            <td className="nombre">
                                                <div className="info-folder puntitos">
                                                    <a id="folder-name" href={'welcomeadmi/' + folder.id + '?idFolders=' + folder.id + '&parent='+folder.nombre_carpeta }className="option-link nombre-carpeta">
                                                        <i className="fa-solid fa-folder-open me-2 ml-2 icono-carpeta"></i> 
                                                        {folder.nombre_carpeta}
                                                    </a> 
                                                </div>
                                            </td>

                                            {/* <!-- Tamaño --> */}
                                            {/* <td className="tamaño">
                                                
                                            </td> */}

                                            {/* <!-- Ubicación de la Carpeta --> */}
                                            <td className="ubicacion">
                                                Mi Escritorio /
                                            </td>

                                            {/* <!-- Fecha --> */}
                                            <td className="fecha">
                                                {format(new Date(folder.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })}
                                            </td>

                                            {/* <!-- Estrellita --> */}
                                            <td className="estrellita" >
                                                <form action="#/" method="POST">
                                                    <label className="star-checkbox">
                                                        <input type="checkbox" 
                                                        checked={favoritesFolders.includes(folder.id)} 
                                                        name="favorito" id={`favorito-${folder.id}`} onChange={(event) => handleFavoriteFoldersToggle(event, folder.id)} />
                                                        <span className="checkmark">
                                                        <i className="fa-regular fa-star"></i>
                                                        <i className="fa-solid fa-star"></i>
                                                        </span>
                                                    </label>
                                                </form>
                                            </td>
                                            
                                            {/* <!-- Tres Puntitos --> */}
                                            <td className="tres-puntitos">  
                                                <div className="dropdown">
                                                    <div className="cursor" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fa fa-ellipsis-v fa-lg"></i>
                                                    </div>

                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <a className="dropdown-item" href="#/" id="myCheckbox" data-bs-toggle="modal" data-bs-target={`#carpetaModal${folder.id}`} aria-hidden="true">
                                                            <i className="fa-solid fa-eye"></i> Ver detalles
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#compartirCarpetaModal${folder.id}`} nameFolder={folder.name}> 
                                                            <i className="fa-solid fa-share-nodes"></i> Compartir 
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <form id="eliminarForm_carpeta_" method="POST" action="">
                                                                <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#confirmarModal${folder.id}`} onClick={openModelDelete} id_subfolder={folder.id} >
                                                                    <i className="fa-solid fa-trash-can"></i> Eliminar
                                                                </a>
                                                            </form>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>

                                            {/* <!-- Modal Detalles de la Carpeta vista ESCRITORIO --> */}
                                            <div className="modal" id={`carpetaModal${folder.id}`} tabIndex="-1" role="dialog" aria-labelledby="carpetaModalLabel" aria-hidden="true">

                                                {/* <!-- Contenido del modal --> */}
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        
                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="carpetaModalLabel">Detalles</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div className="modal-body ">
                                                            <p className="carpeta-tittle">Ubicación</p>
                                                            <p className="info-carpeta">
                                                                {/* {folder.nombreFather} */}
                                                                Mi Escritorio /
                                                            </p>

                                                            <p className="carpeta-tittle">Propietario</p>
                                                            <p className="info-carpeta">
                                                                {folder.pertenece}
                                                            </p>

                                                            <p className="carpeta-tittle">Creado</p>
                                                            <p className="info-carpeta">
                                                            {format(new Date(folder.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })}
                                                            </p>

                                                            {/* <p className="carpeta-tittle">Contenido</p>
                                                                <p className="info-carpeta">
                                                                    No hay archivos en esta carpeta
                                                                </p> */}

                                                            <p className="carpeta-tittle">Descripción</p>
                                                            <p className="info-carpeta">
                                                                {folder.descripcion}
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            {/* <!-- Modal Compartir Carpeta de vista RECIENTES --> */}
                                            <div className="modal" id={`compartirCarpetaModal${folder.id}`} tabIndex="-1" role="dialog" aria-labelledby="compartirCarpetaModallabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">

                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="compartirCarpetaModallabel">Compartir Carpeta</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div className="modal-body d-flex flex-column">
                                                            <div className='d-flex'>
                                                                <p className='title-document-compartido'>Nombre de la Carpeta: </p>
                                                                <p id="compartirNombre">{folder.nombre_carpeta}</p>
                                                            </div>

                                                            <div className='d-flex'>
                                                                <p className='title-document-compartido'>Remitente: </p>
                                                                <p id="compaetirPropietario">{user.username}</p>
                                                            </div>

                                                            <div className='d-flex'>
                                                                <p className='title-document-compartido'>Destinatario: </p>
                                                                <select className='select-compartir' value={destinataryFolder} onChange={(e) => setDestinataryFolder(e.target.value)}>
                                                                    <option value="">Selecciona el Usuario</option>
                                                                    {workers.map((worker) => (
                                                                        worker.nombreCompleto !== '' && 
                                                                        <option key={worker.id} value={worker.nombreCompleto}>{worker.nombreCompleto}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="modal-footer d-flex justify-content-center">
                                                            <button type="submit" id="crear-carpeta" className="btn btn-morado" name="compartirCarpeta_submit" nameFolder={folder.nombre_carpeta} idFolder={folder.id} onClick={onShareFolder}>Compartir</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- Fin Modal --> */}

                                            {/* <!-- Modal Borrar Carpeta --> */}
                                            <div className="modal" id={`confirmarModal${folder.id}`} tabIndex="-1" role="dialog" aria-labelledby="confirmarModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">

                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel">Eliminar Carpeta </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div className="modal-body">
                                                            <p>¿Estás seguro de que deseas eliminar 
                                                                "<b>{folder.nombre_carpeta}"</b>?
                                                            </p>

                                                            <p className ="nota">* Esta será enviada a la <b>Papelera de Reciclaje. </b></p>

                                                            <p className ="nota">* Esta carpeta está compartida, si la eliminas, se eliminará de todos los usuarios con los que la compartiste.</p>
                                                            <b className ="nota"> Usuarios con los que se compartió: </b>
                                                            <ul className ="nota">
                                                                <li>
                                                                    Nombre del destinatario
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="modal-footer d-flex justify-content-center">
                                                            <button type="button" className="btn btn-gris" data-bs-dismiss="modal"><i className="fa-solid fa-ban"
                                                            ></i> Cancelar</button>
                                                            <button type="button" className="btn btn-danger" onClick={onDelete}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 25" fill="none">
                                                                <path d="M4 7.56079H20M10 11.5608V17.5608M14 11.5608V17.5608M5 7.56079L6 19.5608C6 20.0912 6.21071 20.5999 6.58579 20.975C6.96086 21.3501 7.46957 21.5608 8 21.5608H16C16.5304 21.5608 17.0391 21.3501 17.4142 20.975C17.7893 20.5999 18 20.0912 18 19.5608L19 7.56079M9 7.56079V4.56079C9 4.29557 9.10536 4.04122 9.29289 3.85368C9.48043 3.66615 9.73478 3.56079 10 3.56079H14C14.2652 3.56079 14.5196 3.66615 14.7071 3.85368C14.8946 4.04122 15 4.29557 15 4.56079V7.56079" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                            Eliminar</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- Fin Modal --> */}

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            
                {/* <!-- Vista en TABLA de documentos --> */}
                <div className="oculto" id="documentos-filtrados">
                    <div className="documentos document-grid" id="documents">
                        <table className="table-hover">
                            <thead id="tabla-documentos">
                                <tr className="fila-tabla">
                                    <th className="encabezado-tabla"></th>
                                    <th className="encabezado-tabla">Nombre </th>
                                    {/* <th className="encabezado-tabla">Tamaño</th> */}
                                    <th className="encabezado-tabla">Ubicación</th>
                                    <th className="encabezado-tabla">Fecha</th>
                                
                                
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    files.map((file,index) => (
                                        <tr className="fila-tabla" key={index}>
                                            {/* <!-- Imagen del Documento --> */}
                                            <td className="imagen">
                                                <div className="rectangle-86">
                                                </div>

                                                
                                                <div className="doc"> DOC </div>
                                                <a href="#/" className="option-link d-flex justify-content-center"><svg id="img-documento" alt="Icono de un Documento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 84" fill="none">
                                                    <path d="M23.5 7.44263C21.5109 7.44263 19.6032 8.17134 18.1967 9.46847C16.7902 10.7656 16.5 12.7748 16.5 14.6093V42.026V69.6926C16.5 71.527 17.5935 73.2863 19 74.5834C20.4065 75.8806 21.5109 76.6093 23.5 76.6093H68.5C70.4891 76.6093 72.3968 75.8806 73.8033 74.5834C75.2098 73.2863 75.5 71.527 75.5 69.6926V48.9426V28.1926L53.5 7.44263H23.5ZM21.5 12.526H51V27.526L70.5 28.1926V48.9426V71.1093H46.5H21.5V42.026V12.526ZM31 42.026V46.1093H45.5H61V42.026H31ZM31 56.1093V60.1093H41H50V56.1093H41H31Z" fill="black"/></svg>
                                                </a>
                                            </td>

                                            {/* <!-- Nombre del Documento --> */}
                                            <td className="nombre">
                                                <div className="info-folder puntitos">
                                                    <a className="option-link nombre-carpeta" id="folder-name" href="#/" urlFile={file.url_documento} onClick={handleOpenModalVisualization}  data-bs-toggle="modal" data-bs-target="#visualizeDocumentModal">
                                                    <i className="fa-regular fa-file-lines me-2 ml-2 icono-doc"></i> {file.nombre}.{file.extension}
                                                    </a>
                                                </div>
                                            </td>
                                            
                                            {/* <!-- Tamaño del Documento --> */}
                                            {/* <td className="tamaño">
                                                
                                            </td> */}

                                            {/* <!--  Ubicación del Documento --> */}
                                            <td className="ubicacion">
                                                Mi Escritorio /
                                            </td>
                                            
                                            {/* <!-- Fecha de Creación del Documento --> */}
                                            <td className="fecha">
                                                {format(new Date(file.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })}
                                            </td>
                                            
                                            {/* <!-- Estrellita --> */}
                                            <td className="estrellita">
                                                <form action="" method="POST">
                                                    <label className="star-checkbox">
                                                        <input type="checkbox" 
                                                            checked={favorites.includes(file.id)} 

                                                            name="favorito" id={`favorito-${file.id}`} onChange={(event) => handleFavoriteToggle(event, file.id)}
                                                        />
                                                        <span className="checkmark">
                                                            <i className="fa-regular fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                        </span>
                                                    </label>
                                                </form>
                                            </td>
                                            
                                            {/* <!-- Tres Puntitos --> */}
                                            <td className="tres-puntitos">
                                                <div className="dropdown">
                                                    <div className="cursor" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fa fa-ellipsis-v fa-lg"></i>
                                                    </div>

                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <a className="dropdown-item" href="#/" id="myCheckbox" data-bs-toggle="modal" data-bs-target={`#archivoModal${file.id}`} >
                                                            <i className="fa-solid fa-eye"></i> Ver detalles
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#compartirDocumentoModal${file.id}`} namefile={file.nombre}>
                                                            <i className="fa-solid fa-share-nodes"></i> Compartir
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a className="dropdown-item" href="#/" >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16" 
                                                            > <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/><path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/></svg> 
                                                            Descargar
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#verVersionesModal${file.id}`} idFile={file.id} onClick={openModalVersions} nameFile={file.nombre}>
                                                            <i className="fa-regular fa-copy" ></i> Versiones
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#confirmarModalDoc${file.id}`} onClick={openModelDeleteFile} idfile={file.id}>
                                                                <i className="fa-solid fa-trash-can"></i> Eliminar
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#firmarModalDoc${file.id}`} >
                                                                <i className="fa-solid fa-pen-nib"></i> Firmar
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>

                                            {/* <!-- Modal Firmar Documento --> */}
                                            <div className="modal" id={`firmarModalDoc${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="firmarModalDocLabel" aria-hidden="true">

                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">

                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="firmarModalLabel"> Firmar Documento </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div className="modal-body">
                                                            <form method="POST" >
                                                                <p><b>Coloca el identificador que contiene el contrato.</b></p>
                                                                <p className="fw-medium text-muted fs-7">El nombre del identificador debe ir dentro de corchetes "[ ]"</p>
                                                                <input type="text" name="identificador" placeholder="Ejemplo: [Firma del cliente]" 
                                                                required/>
                                                                <canvas className="canvas" id="canvas{{ documento_pdf.id }}" width="430" height="200"></canvas>
                                                                <br/>
                                                                
                                                                <input type="hidden" name="firma" id="firma{{ documento_pdf.id }}"/>
                                                                <input type="hidden" name="carpeta" value=""/>
                                                                <input type="hidden" name="documento" value=""/>
                                                                <input type="hidden" name="documentoId" value=""/>
                                                                <br/>
                                                            </form>
                                                        </div>

                                                        <div className=" modal-footer d-flex justify-content-center ">
                                                            <button className="btn btn-gris " type="button" 
                                                            > Limpiar </button>
                                                            <button className="btn btn-morado" type="submit" name="firmaguardar"> Guardar Firma </button>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            {/* <!-- Modal Detalle del Archivo --> */}
                                            <div className="modal" id={`archivoModal${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="archivoModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        
                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="archivoModalLabel">Detalles</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div className="modal-body">
                                                            {/* Tamaño del Archivo */}
                                                            {/* <p className="carpeta-tittle">Tamaño del Archivo</p>
                                                            <p className="info-carpeta">
                                                                5KB
                                                            </p> */}

                                                            {/* Ubicación */}
                                                            <p className="carpeta-tittle">Ubicación</p>
                                                            <p className="info-carpeta">
                                                                Mi Escritorio /
                                                            </p>

                                                            {/* Propietario */}
                                                            <p className="carpeta-tittle">Propietario</p>
                                                            <p className="info-carpeta">
                                                                {user.username}
                                                            </p>

                                                            {/* Fecha de Creación */}
                                                            <p className="carpeta-tittle">Creado </p>
                                                            <p className="info-carpeta">
                                                            {format(new Date(file.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })}
                                                            </p>

                                                            {/* Descripción */}
                                                            <p className="carpeta-tittle">Descripción</p>
                                                            <p className="info-carpeta">
                                                                {file.descripcion}
                                                            </p>

                                                            {/* <div className="mofal-footer d-flex justify-content-end">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="btn btn-light bi bi-pencil-square" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#firmarModalDoc">
                                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                                                </svg>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- Fin Modal Detalle del Archivo --> */}

                                            {/* <!-- Modal Compartidos --> */}
                                            <div className="modal" id={`compartirDocumentoModal${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="compartirDocumentoModallabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content"> 

                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="compartirDocumentoModallabel"> Compartir Documento </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div className="modal-body d-flex flex-column">
                                                            <div className='d-flex'>
                                                                <p className='title-document-compartido'>Nombre del Documento: </p>
                                                                <p id="compartirNombre">{file.nombre + '.' + file.extension}</p>
                                                            </div>

                                                            <div className='d-flex'>
                                                                <p className='title-document-compartido'>Remitente: </p>
                                                                <p id="compaetirPropietario">{user.username}</p>
                                                            </div>

                                                            <div className='d-flex'>
                                                                <p className='title-document-compartido align-items-center'>Destinatario: </p>
                                                                <select className='select-compartir' value={destinatary} onChange={(e) => setDestinatary(e.target.value)}>
                                                                    <option value="">Selecciona el Usuario</option>
                                                                    {workers.map((worker) => (
                                                                        worker.nombreCompleto !== '' && 
                                                                        <option key={worker.id} value={worker.nombreCompleto}>{worker.nombreCompleto}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="modal-footer d-flex justify-content-center">
                                                            <button name="compartirDocumento_submit" id="crear-documento" nameFile={file.nombre} idFile={file.id} onClick={onShareFile} className="btn btn-morado"> Compartir
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- Fin Modal --> */}

                                            {/* <!-- Modal Eliminar Documento --> */}
                                            <div className="modal" id={`confirmarModalDoc${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="confirmarModalDocLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">

                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel"> Eliminar Documento </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        
                                                        <div className="modal-body">
                                                            <p>¿Estás seguro de que deseas ELIMINAR <b>
                                                                "{file.nombre}.{file.extension}"?
                                                            </b></p>
                                                            <p className ="nota">* Este será enviado a la <b>Papelera de Reciclaje. </b></p>
                                                            <p className ="nota"> * Esta carpeta está compartida, si la eliminas, se dejará de compartir.</p>
                                                            <b className ="nota">Usuarios con los que se compartió:</b>
                                                            <ul className="nota">
                                                                <li>
                                                                    Nombre del destinatario
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="modal-footer d-flex justify-content-center">
                                                            <button type="button" className="btn btn-gris " data-bs-dismiss="modal"><i className="fa-solid fa-ban" 
                                                            ></i> Cancelar </button>

                                                            <button type="button" className="btn btn-danger" onClick={onDeleteFile}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 25" fill="none">
                                                                <path d="M4 7.56079H20M10 11.5608V17.5608M14 11.5608V17.5608M5 7.56079L6 19.5608C6 20.0912 6.21071 20.5999 6.58579 20.975C6.96086 21.3501 7.46957 21.5608 8 21.5608H16C16.5304 21.5608 17.0391 21.3501 17.4142 20.975C17.7893 20.5999 18 20.0912 18 19.5608L19 7.56079M9 7.56079V4.56079C9 4.29557 9.10536 4.04122 9.29289 3.85368C9.48043 3.66615 9.73478 3.56079 10 3.56079H14C14.2652 3.56079 14.5196 3.66615 14.7071 3.85368C14.8946 4.04122 15 4.29557 15 4.56079V7.56079" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                                Eliminar
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- Fin Modal Eliminar Documento --> */}
                                            
                                            {/* <!--Modal tabla versiones--> */}
                                            <div className="modal" id={`verVersionesModal${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="verVersionesModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                                                    <div className="modal-content">
                                                        
                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel">Versiones del Documento "{file.nombre}.{file.extension}"
                                                            </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                            
                                                        <div className="modal-body">
                                                            <div className="d-flex justify-content-end">
                                                                <button className="btn btn-dark mb-2" data-bs-toggle="modal" data-bs-target={`#versionesModal${file.id}`} >
                                                                    + Subir Versión
                                                                </button>
                                                            </div>
                                                            
                                                            <div className="row m-2 d-flex">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-2 fw-bold">Versión</div>
                                                                        <div className="col-2 fw-bold">Usuario</div>
                                                                        <div className="col-3 fw-bold">Descripción</div>
                                                                        <div className="col-3 fw-bold">Fecha de Creación</div>
                                                                        <div className="col-2 fw-bold">Acciones</div>
                                                                    </div>
                                                                    {versions.map( (vers,vindex)  => (
                                                                        <div className="row mb-2">
                                                                            <div className="col-2"> {vers.version} </div>
                                                                            <div className="col-2 "> {vers.usuario_id.username} </div>
                                                                            <div className="col-3 "> {vers.descripcion}</div>
                                                                            <div className="col-3 "> {format(new Date(vers.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })}</div>
                                                                            <div className="col-2 p-0 d-flex">
                                                                                <div className="btn-version">
                                                                                    <a href="#/" className="btn" target="_blank">
                                                                                        <i className="fa-regular fa-eye"></i>
                                                                                    </a>
                                                                                </div>
                                                                                
                                                                                <div className="btn-version">
                                                                                    <a href="#/" className="btn">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                                                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                                                                    </svg>
                                                                                    </a>
                                                                                </div>
                                                                                
                                                                                <div className="btn-version">
                                                                                    <a href='#/' className="eliminarButton btn" data-bs-toggle="modal" data-bs-target={`#confirmarVersionModalDoc${file.id}`}  onClick={openModelDeleteFileVersions} idVersion={vers.id} > 
                                                                                        <i className="fa-solid fa-trash-can"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            {/* <!--Modal tabla subir version--> */}
                                            <div className="modal" id={`versionesModal${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="versionesModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered " role="document">
                                                    <div className="modal-content">
                                                    
                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel">Subir Versión de Documento "{file.nombre}.{file.extension}"</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        
                                                        <div className="modal-body">
                                                            <form method="POST" id="nueva-carpeta-form" acceptCharset="UTF-8" encType="multipart/form-data">
                                                                {/* <input type="hidden" name="{{ formVersionado.documentoPadre.name }}" value="{{ documento_pdf.id }}"/>
                                                                <input type="hidden" name="{{ formVersionado.carpeta.name }}" value="{{ documento_pdf.carpeta }}"/> */}

                                                                <label htmlFor="" className="col-form-label titulo-label">Archivo</label>
                                                                <input type="file" id="compartirNombre" onChange={handleArchivoVersions} name=""/>
                                                                <br/>

                                                                <div className="mb-3">
                                                                    <label htmlFor="message-text" className="col-form-label titulo-label">Descripción</label>
                                                                    <textarea className="form-control content-textarea" id="message-text" onChange={(e) => setVersionDescripcion(e.target.value)} placeholder='Ingresa la descripción de la versión'></textarea>
                                                                </div>  
                                                                {/* <label htmlFor="{{ formVersionado.descripcion.id_for_label }}">Descripción</label>
                                                                <input id="compartirNombre" name="{{ formVersionado.descripcion.name }}" /> */}
                                                                {errores && <p class="alert alert-danger" role="alert" >{errores}</p>}
                                                                {success && <p className="alert alert-success" role="alert">{success}</p>}
                                                            </form>
                                                        </div>

                                                        <div className="modal-footer d-flex justify-content-center">
                                                            <button name="" className="btn btn-morado"  onClick={onSendVersion}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" 
                                                                width="16" height="16" fill="currentColor" className="bi bi-upload btn-upload-icon" viewBox="0 0 16 16">
                                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                                                </svg> Subir
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            {/* <!-- Modal Eliminar Versiónn --> */}
                                            <div className="modal" id={`confirmarVersionModalDoc${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="confirmarVersionModalDocLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered " role="document">
                                                    <div className="modal-content">

                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel">Confirmar Eliminar Documento</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        
                                                        <div className="modal-body">
                                                            <p>¿Estás seguro de que deseas ELIMINAR la versión <b> '{file.versions}'</b> del documento <b>"{file.nombre}.{file.extension}" </b></p>
                                                            <p className ="nota">Nota: esta versión se enviará a la <b>Papelera de Reciclaje</b></p>
                                                        </div>

                                                        <div className="modal-footer d-flex justify-content-center">
                                                            <button type="button" className="btn btn-gris" data-dismiss="modal"><i className="fa-solid fa-ban"></i> Cancelar</button>

                                                            <button type="button" className="btn btn-danger" onClick={onDeleteFileVersion} 
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 25" fill="none">
                                                                <path d="M4 7.56079H20M10 11.5608V17.5608M14 11.5608V17.5608M5 7.56079L6 19.5608C6 20.0912 6.21071 20.5999 6.58579 20.975C6.96086 21.3501 7.46957 21.5608 8 21.5608H16C16.5304 21.5608 17.0391 21.3501 17.4142 20.975C17.7893 20.5999 18 20.0912 18 19.5608L19 7.56079M9 7.56079V4.56079C9 4.29557 9.10536 4.04122 9.29289 3.85368C9.48043 3.66615 9.73478 3.56079 10 3.56079H14C14.2652 3.56079 14.5196 3.66615 14.7071 3.85368C14.8946 4.04122 15 4.29557 15 4.56079V7.56079" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg> Eliminar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- Fin Modal Eliminar Versión --> */}

                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>  
                    </div>
                </div>

                {showModalVisualization && <DocumentModal
                    documentUrl={urlToVisualize}
                    onClose={handleCloseModalVisualization}
                />} 

            </div>
            
        </>
    )
}




