import { useEffect, useState } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';

export const usePapeleraFolders = (user = null, idFather = null, nombreFather = null) => {
    const [folders, setPapeleraFolders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);
  
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError('');
        
            try {
                var response = {};
                if(idFather==null){ // Estoy en home
          
                    if (user.rol === "admin") {
                      // Es un usuario administrador
                      response = await axios.get(`${urlHostTenant}/folders/papelerafolder?nombreFather=${user.id}&usuario=${user.username}`,
                        {headers: {
                          Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                          'X-DTS-SCHEMA': xDtsSchemaTenant,
                      }}
                      );
                      // http://hyperoxbiz.localhost:8000/folders/papelerafolder/
                    } else {
                      // worker
                      response = await axios.get(`${urlHostTenant}/folders/papelerafolder?nombreFather=${user.id}&usuario=${user.nombreCompleto}`,
                        {headers: {
                          Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                          'X-DTS-SCHEMA': xDtsSchemaTenant,
                      }}
                      );
                    }
                  }else{ // Estoy ensubcarpeta
            
                    if(user.rol === "admin") {
                      response = await axios.get(`${urlHostTenant}/folders/papelerafolder?nombreFather=${nombreFather}&idFather=${idFather}&usuario=${user.username}`,
                        {headers: {
                          Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                          'X-DTS-SCHEMA': xDtsSchemaTenant,
                      }}
                      );
            
                    }else{//worker
                      response = await axios.get(`${urlHostTenant}/folders/papelerafolder?nombreFather=${nombreFather}&idFather=${idFather}&usuario=${user.nombreCompleto}`,
                        {headers: {
                          Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                          'X-DTS-SCHEMA': xDtsSchemaTenant,
                      }}
                      );
            
                    }
            
                  }
                console.log('folders', response)
                setPapeleraFolders(response.data.data);
            } catch (error) {
                setError(error);
            }
        
            setLoading(false);
        };
    
        fetchData();
    }, [user, idFather, nombreFather]);

    return { folders, loading, error };
};
