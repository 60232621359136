import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import imagenequipo1 from '../assets/jirafita.jpg';
import axios from "axios";
import { useAuth } from '../../router/Auth';
// import {useTeams} from '../../hooks/useTeams';
import { useSelector } from 'react-redux';


export const Equipos = () => {
    const {user, login} = useAuth();
    const navigate = useNavigate();

    const [area, setArea] = useState('')
    const [image, setImage] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [nameEquipo, setNameEquipo] = useState('')
    // const [fechaCreacion, setfechaCreacion] = useState('')
    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);
  

    useEffect(() => {
        document.title = 'Configuración de Equipos - Lyvian'; // Cambia el título de la página cuando el componente se monta

        return () => {
            document.title = 'Lyvian'; // Restaura el título predeterminado cuando el componente se desmonta
        };
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
    };
    

    const onSubmitEquipo = useCallback(async (event) => {
        event.preventDefault();
    
        const formData = new FormData();
        formData.append("nombre_equipo", nameEquipo);
        formData.append("area", area);
        formData.append("trabajadores", ""); // Si es necesario
        formData.append("creador", user.id);
        formData.append("descripcion", descripcion);
        if (image) {
            formData.append("imagen", image);
        }
    
        try {
            const response = await axios.post(
                `${urlHostTenant}/teams/team/`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                }
            );
    
            alert("¡Registro exitoso!");
            navigate(0); // O puede ser navigate("/algunaRuta") si deseas redirigir a otra parte
        } catch (error) {
            console.error("Error", error);
            alert("Ocurrió un error durante el registro. Por favor inténtalo nuevamente.");
        }
    }, [nameEquipo, area, user, descripcion, image, navigate]);
    


    return (
        <div className="vista-panel-equipos">
            {/* <h2>Equipos</h2> */}

            {/* Botones Crear un Equipo */}
            <div className="row d-flex justify-content-center" >
                <div className="col-auto btn-group" role="group" aria-label="Basic radio toggle button group">

                    <button className="btn btn-light" type="button" data-bs-toggle="modal" data-bs-target="#nuevoEquipoModal">
                        <i className="fa-solid fa-users"></i> Crear Equipo
                    </button>

                    <div className="dropdown">
                        {/**id="dropdownMenuButton2" --> esto le coloca el borde gris a los botones de arriba */} 
                        <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            Equipos por área
                        </button>

                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className="dropdown-item" href="#/">Área de Equipo 1</a></li>
                            <li><a className="dropdown-item" href="#/">Área de Equipo 2</a></li>
                            <li><a className="dropdown-item" href="#/">Área de Equipo 3</a></li>
                        </ul>
                    </div>
                </div>
                
            </div>
            
            <div className="cards_equipos">

                {/* {teams.map((folder,index) => ( */}
                    <div className="card equipos-card mt-2">
                        <div className="d-flex justify-content-end cursor puntitos-equipos">
                            <i id="myCheckbox" data-bs-toggle="modal" data-bs-target="#equipoModal" className="fa-solid fa-ellipsis-vertical"></i>
                        </div>

                        <img src={imagenequipo1} className="d-flex" id="img-equipo" alt="Descripción de la Imagen"/>
                        <div className="card-body" 
                            // style="margin-top:20px;"
                        >
                            <h5>
                                <a className='m-0 nombre-equipo text-decoration-none' id="" href="#/">
                                    Nombre del Equipo
                                </a>
                            </h5>

                            <div className="area">
                                Area {/* {{ equipo.area|lower }} */}
                            </div>
                            <p className="card-text">
                                <small className="text-muted">
                                8 integrantes
                                </small>
                            </p>

                            <small><b>
                                {/* {{ equipo.total_size_gb_equipo | floatformat:2 }} */}
                            GB utilizados</b></small> 

                            <div className="storage">
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" 
                                    // style="width: {{ equipo.espacio_utilizado_percent_equipo }}%; background-color: purple;" 
                                    aria-valuenow="" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                                <div className="space-info d-flex justify-content-between">
                                    <div>0</div>
                                    <div>
                                        {/* {{ equipo.mitad_espacio | floatformat:2 }} */}
                                    </div>
                                    <div>
                                        {/* {{ equipo.limite_espacio }} */}
                                    TB</div>   
                                </div>
                            </div>

                            <a href="/paneladmi/detallesequipos" className="d-flex justify-content-end detalles-equipos m-0 text-decoration-none"><b>Ver detalles</b></a>
                        </div>
                    </div>
                {/* ))}  */}
                
            </div>

            {/* <!-- Modal Nuevo Equipo --> */}
            <div className="modal" id="nuevoEquipoModal" tabIndex="-1" role="dialog" aria-labelledby="nuevoEquipoModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title col-11 d-flex justify-content-center" id="nuevoEquipoModalLabel">Crear Equipo</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <form method="POST" id="nuevo-equipo-form" enctype="multipart/form-data">
                                {/* <p className='text-center texto-equipo'>Escribe un nombre y elige una imagen para el equipo.</p> */}
                                <label htmlFor="recipient-name" className="col-form-label titulo-label">Nombre</label>
                                <input type="text" className="form-control content-input" id="recipient-name" placeholder='Nombre del Equipo' onChange={(e) => setNameEquipo(e.target.value)}/>

                                <div className="">
                                    <label htmlFor="message-text" className="col-form-label titulo-label">Descripción</label>
                                    <textarea className="form-control content-textarea" id="message-text" placeholder='Descripción del Equipo' onChange={(e) => setDescripcion(e.target.value)}></textarea>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="message-text" className="col-form-label titulo-label">Area</label>
                                    <input type="text" className="form-control content-input" id="recipient-name" placeholder='Area de trabajo al que perteneces' onChange={(e) => setArea(e.target.value)}/>                                
                                    </div>

                                <label htmlFor="recipient-name" className="col-form-label titulo-label">Selecciona una imagen </label>
                                <input className="form-control" type="file" id="inputFile" onChange={handleFileChange} />
                            </form>
                        </div>

                        <div className="modal-footer justify-content-center">
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn btn-gris d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className='me-2' width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M9.99984 1.66669C14.5832 1.66669 18.3332 5.41669 18.3332 10C18.3332 14.5834 14.5832 18.3334 9.99984 18.3334C5.4165 18.3334 1.6665 14.5834 1.6665 10C1.6665 5.41669 5.4165 1.66669 9.99984 1.66669ZM9.99984 3.33335C8.4165 3.33335 6.99984 3.83335 5.9165 4.75002L15.2498 14.0834C16.0832 12.9167 16.6665 11.5 16.6665 10C16.6665 6.33335 13.6665 3.33335 9.99984 3.33335ZM14.0832 15.25L4.74984 5.91669C3.83317 7.00002 3.33317 8.41669 3.33317 10C3.33317 13.6667 6.33317 16.6667 9.99984 16.6667C11.5832 16.6667 12.9998 16.1667 14.0832 15.25Z" fill="#393939"/>
                                </svg>
                                Cancelar
                            </button>
                            <button type="submit" name="nuevo_equipo" className="btn btn-morado align-items-center" onClick={onSubmitEquipo}> Crear Equipo</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Fin Modal Nuevo Equipo --> */}
            
        </div>
    )
}
