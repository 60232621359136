import React, { useCallback, useEffect ,useState} from 'react'

import { es } from 'date-fns/locale';
import imagenfolder from '../assets/folder.png';
import axios from "axios";
import { useAuth } from '../../router/Auth';
import { useNavigate } from 'react-router-dom';
import useUsers from '../../hooks/useUsers';
import useVersions from '../../hooks/useVersionsFile';
import useFolderShared from '../../hooks/useFolderShared';
import useFileShared from '../../hooks/useFilesShared';
import useAdministradores from '../../hooks/useAdministradores';
import { useSelector, useDispatch } from 'react-redux';


export const VistaUserCompartidos = () => {
    const { format } = require('date-fns');

    const {user} = useAuth();

    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema); 
    
    const {foldersSharedForMe,foldersSharedByMe,loading : loadingShareFolder} = useFolderShared(user)
    const {filesSharedForMe,filesSharedByMe,loading : loadingShareFile} = useFileShared(user)
    const [listFolderShared, setListFolderShared] = useState([])
    const [listFilesShared, setListFilesShared] = useState([])

    const {workers, loading: workersIsLoading} = useUsers() // se trae a todos los usuarios
    const {admins, loading: adminsIsLoading} = useAdministradores() //se trae a los administradores

    // Fusionar los arrays de workers y admins
    const allUsers = [...workers, ...admins];

    // Eliminar
    const [toDeleteFileVersionID, setToDeleteFileVersionId] = useState([]) // eliminar documento desde la modal de versiones

    // versions
    const {versions, setIdFile} = useVersions()
    const [versionToUpload, setVersionToUpload] = useState(null)
    const [idFileOpen, setIdFileOpen] = useState("") // versiones
    const [nameFileOpen, setnameFileOpen] = useState("") // versiones

    // Favoritos
    const [favoritesFolders, setFavoritesFolders] = useState([]); 
    const [favoritesFiles, setFavoritesFiles] = useState([]); 

    // Compartidos
    const [destinatary, setDestinatary] = useState("") 
    const [destinataryFolder, setDestinataryFolder] = useState("") 


    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Compartidos - Lyvian';

        setListFolderShared([...foldersSharedForMe,...foldersSharedByMe])
        setListFilesShared([...filesSharedByMe,...filesSharedForMe])

        const favoritosFoldersIds = [...foldersSharedForMe,...foldersSharedByMe].filter(folder => folder.favorito).map(folder => folder.id);
        setFavoritesFolders(favoritosFoldersIds);
    

        const favoritosIds = [...filesSharedByMe,...filesSharedForMe].filter(file => file.favorito).map(file => file.id);
        setFavoritesFiles(favoritosIds);

    }, [loadingShareFile,loadingShareFolder, foldersSharedByMe, foldersSharedForMe, filesSharedByMe, filesSharedForMe]);
    
    // Enviar una version del documetno
    const onSendVersion= useCallback (async () => {
        try {
            let formData = new FormData();
            formData.append('carpeta_id', user.nombreCompleto);
            formData.append('trabajador_id', user.id);
            formData.append('archivo', versionToUpload);
            formData.append('documentoPadre_id', idFileOpen);
            formData.append('nombre_documento_padre', nameFileOpen);
            formData.append('descripcion', '');
            console.log("idf",idFileOpen,nameFileOpen)

            // setIsLoading(true); estado de carga para el usuario
            await axios.post(`${urlHostTenant}/documents/documentVersion/`, formData, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [versionToUpload,user,idFileOpen,nameFileOpen,navigate]);
    
    // Modal Versiones
    const openModalVersions = (event) =>{
        event.preventDefault()
        const idFile= event.currentTarget.getAttribute('idFile')
        const nameFileOpen= event.currentTarget.getAttribute('nameFile')
        setIdFile(idFile)
        setIdFileOpen(idFile)
        setnameFileOpen(nameFileOpen)
    }

    const handleArchivoVersions = (e) => {
        const archivo = e.target.files;
        console.log("archivo",archivo)
        setVersionToUpload(archivo[0])
    };

    // Eliminar Documentos Version desde la modal
    const onDeleteFileVersion = useCallback (async () => {
        try {
            await axios.delete(`${urlHostTenant}/documents/documentVersion/${toDeleteFileVersionID}`, { }, 
            {
                headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate,toDeleteFileVersionID]);

    // Modal para Eliminar un Documento Version
    const openModelDeleteFileVersions = (event) => {
        event.preventDefault()
        const idVersion = event.currentTarget.getAttribute('idVersion');
        setToDeleteFileVersionId(idVersion);
    }

    // -------------------------------------------------------------

    // Favoritos Carpetas
    const handleFavoriteFoldersToggle = async (event, carpeta_id) => {
        const isChecked = event.target.checked;

        try {
            if (isChecked) {
                setFavoritesFolders([...favoritesFolders, carpeta_id]);

                // Si el folder se marca como favorito, enviar al backend
                await axios.post(`${urlHostTenant}/folders/favoritefolder/`, { carpeta_id, trabajador_id : user.id },
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
            } else {
                setFavoritesFolders(favoritesFolders.filter(id => id !== carpeta_id));
                
                // Si el folder se desmarca como favorito, enviar al backend
                await axios.delete(`${urlHostTenant}/folders/favoritefolder/?carpeta_id=${carpeta_id}&trabajador_id=${user.id}`,
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
            }
        } catch (error) {
            console.error('Error al actualizar favorito:', error);
        }
    };

    // Favoritos Documentos
    const handleFavoriteFileToggle = async (event, documento_id) => {
        const isChecked = event.target.checked;

        try {
            if (isChecked) {
                setFavoritesFiles([...favoritesFiles, documento_id]);

                // Si el documento se marca como favorito, enviar al backend
                await axios.post(`${urlHostTenant}/documents/favoritedocuments/`, { documento_id, trabajador_id : user.nombreCompleto },
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
                
            } else {
                setFavoritesFiles(favoritesFiles.filter(id => id !== documento_id));
                
                // Si el documento se desmarca como favorito, enviar al backend
                await axios.delete(`${urlHostTenant}/documents/favoritedocuments/1?documento_id=${documento_id}&trabajador_id=${user.nombreCompleto}`,
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
            }
        } catch (error) {
            console.error('Error al actualizar favorito:', error);
        }
    };

    // --------------------------------------------------------------

    // ME FALTA RESOLVER ESTO DE DESCOMPARTIR UN DOCUMENTO Y CARPETA
    // Descompartir un archivo
    const onDescompartirFile= useCallback (async (event) => {
        const idFile= event.currentTarget.getAttribute('idFile')

        try {
            await axios.delete(`${urlHostTenant}/shared/shareDocument/documento_id:${idFile}&usuario:${user.nombreCompleto}`, {}, 
            {
                headers: {
                    Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                    'X-DTS-SCHEMA': xDtsSchemaTenant,
                },
                })
            
        } catch (error){
            console.log('Error', error) // ilustracion para el usuario
            alert(error.response.data.data);
        } finally {
            navigate(0)
        }
    }, [navigate,user]);

    // Descompartir una Carpeta
    const onDescompartirFolder = useCallback(async (idFolder, nombreUsuario) => {
        try {
            await axios.delete(`${urlHostTenant}/shared/shareFolder/carpeta_id:${idFolder}&usuario:${nombreUsuario}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    'X-DTS-SCHEMA': xDtsSchemaTenant,
                },
            });
        } catch (error) {
            console.log('Error', error);
            alert(error.response.data.data);
        } finally {
            navigate(0);
        }
    }, [navigate]);

    // -----------------------------------------------------------
    // Filtrossss
    const onClickShareByMe = () =>{
        setListFolderShared(foldersSharedByMe)
        setListFilesShared(filesSharedByMe)
    }
    
    const onClickShareForMe = () =>{
        setListFolderShared(foldersSharedForMe)
        setListFilesShared(filesSharedForMe)
    }

    const onClickShareGeneral = () =>{
        setListFolderShared([...foldersSharedByMe,...foldersSharedForMe])    
        setListFilesShared([...filesSharedByMe,...filesSharedForMe])    
    }


    return (
        <>
            <div className="vista-user">
                <div className="row ">
                    <div className="col">
                        <nav className="nav nav-underline">
                            <a className="nav-link" href="#/" onClick={ onClickShareGeneral} data-filter="compartidos_general">Compartidos General</a>
                            <a className="nav-link" href="#/" onClick={onClickShareByMe} data-filter="compartidos_por_mi">Compartidos Por Mí</a>
                            <a className="nav-link" href="#/" onClick={onClickShareForMe } data-filter="compartidos_para_mi">Compartidos Conmigo</a>
                        </nav>
                    </div>
                </div>
                
                {/* <!-- ***** Carpetas en TABLA vista  ***** --> */}
                <div className="oculto" id="carpetas-filtradas">
                    <div className="carpetas" id="carpets">
                        <table className="table-hover">
                            <thead>
                                <tr className="fila-tabla">
                                    <th className="encabezado-tabla"></th>
                                    <th className="encabezado-tabla pl-2">Nombre </th>
                                    {/* <th className="encabezado-tabla">Tamaño</th> */}
                                    <th className="encabezado-tabla">Ubicación</th>
                                    <th className="encabezado-tabla">Fecha</th>
                                </tr>
                            </thead>

                            <tbody>
                                {listFolderShared.map((folder, index) => {
                                    const destinatarios = Array.isArray(folder.destinataries) ? folder.destinataries : [];
                                    return (
                                        <tr className="fila-tabla" key={index}>
                                            {/* <!-- Imagen de la Carpeta --> */}
                                            <td className="imagen">
                                                <a href={'/welcomeuser/'+folder.id + '?parent='+folder.nombre_carpeta} className="option-link d-flex justify-content-center" >
                                                    <img src={imagenfolder} id="img-folder" alt="Imagen de una Carpeta" />
                                                </a>
                                            </td>

                                            {/* <!-- Nombre de la Carpeta --> */}
                                            <td className="nombre">
                                                <div className="info-folder puntitos">
                                                <a id="folder-name" href={'/welcomeuser/'+folder.id + '?parent='+folder.nombre_carpeta} className="option-link nombre-carpeta"><i className="fa-solid fa-folder-open icono-carpeta"></i>
                                                        {folder.nombre_carpeta}
                                                    </a>
                                                </div>
                                            </td>
                        
                                            {/* <!-- Tamaño --> */}
                                            {/* <td className="tamaño">
                                            </td> */}
                        
                                            {/* <!-- Ubicación de la Carpeta --> */}
                                            <td className="ubicacion">
                                                {folder.nombreFather} /
                                            </td>
                        
                                            {/* <!-- Fecha --> */}
                                            <td className="fecha">
                                                {format(new Date(folder.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })}
                                            </td>
                        
                                            {/* <!-- Estrellita --> */}
                                            <td className="estrellita mr-4" >
                                                <form action="{% url 'marcar_favorito_carpeta' contenido.id usuario.id vista %}" method="POST">
                                                    <div className="star-position-subcarpetas mr-3">
                                                        <label className="star-checkbox">
                                                        <input type="checkbox" 
                                                            checked={favoritesFolders.includes(folder.id)} 
                                                            name="favorito" id={`favorito-${folder.id}`} onChange={(event) => handleFavoriteFoldersToggle(event, folder.id)} />                                                        <span className="checkmark">
                                                                <i className="fa-regular fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </form>
                                            </td>

                                            {/* <!-- Tres Puntitos --> */}
                                            <td className="tres-puntitos">  
                                                <div className="dropdown mt-2">
                                                    <div className="cursor" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fa fa-ellipsis-v fa-lg"></i>
                                                    </div>
                            
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <a className="dropdown-item" href="#/" id="myCheckbox" data-bs-toggle="modal" data-bs-target={`#subcarpeta_recienteModal${folder.id}`} aria-hidden="true">
                                                                <i className="fa-solid fa-eye"></i> Ver detalles
                                                            </a>
                                                        </li>
                                
                                                        <li>
                                                            <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#confirmarModalCarpetaCompartida${folder.id}`} >
                                                                <i className="fa-solid fa-share-nodes"></i> Descompartir 
                                                            </a>
                                                        </li> 
                                                    </ul> 
                                                </div>
                                            </td>
                        
                                            {/* <!-- Modal Detalles --> */}
                                            <div className="modal" id={`subcarpeta_recienteModal${folder.id}`} tabIndex="-1" role="dialog" aria-labelledby="subcarpeta_recienteModalLabel" aria-hidden="true">
                                                {/* <!-- Contenido del modal --> */}
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                        
                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="subcarpeta_recienteModalLabel"> Detalles </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                
                                                        <div className="modal-body">
                                                            <p className="carpeta-tittle">Ubicación</p>
                                                            <p className="info-carpeta">
                                                                {folder.nombreFather} /
                                                            </p>
                            
                                                            <p className="carpeta-tittle">Propietario</p>
                                                            <p className="info-carpeta">
                                                                {folder.pertenece}
                                                            </p>
                            
                                                            <p className="carpeta-tittle">Creado</p>
                                                            <p className="info-carpeta">
                                                                {format(new Date(folder.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })}
                                                            </p>
                            
                                                            <p className="carpeta-tittle">Compartido con</p>
                                                            <ul className="info-carpeta">
                                                                {destinatarios.map((nameDestinatary, index) => (
                                                                    <li key={index}>{nameDestinatary}</li>
                                                                ))} 
                                                            </ul>
                            
                                                            <p className="carpeta-tittle">Descripción</p>
                                                            <p className="info-carpeta">
                                                                {folder.descripcion}
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- Modal Confirmar descompartir --> */}
                                            <div className="modal" id={`confirmarModalCarpetaCompartida${folder.id}`} tabIndex="-1" role="dialog" aria-labelledby="confirmarModalDocLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel">Dejar de Compartir</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>¿Estás seguro de que deseas descompartir esta carpeta: <b>"{folder.nombre_carpeta}"?</b></p>
                                                            <p>Usuarios con los que compartiste esta carpeta:</p>
                                                            <ul>
                                                                
                                                            {destinatarios.map((nameDestinatary, index) => (
                                                                <li key={index}>{nameDestinatary}</li>
                                                            ))}                                                                    
                                                                
                                                            </ul>
                                                            <div className='d-flex'>
                                                                <select className="select-compartir" value={destinataryFolder} onChange={(e) => setDestinataryFolder(e.target.value)}>
                                                                    <option value="">Selecciona un usuario</option>
                                                                    {destinatarios.map((nameDestinatary, index) => (
                                                                        <option key={index} value={nameDestinatary}>{nameDestinatary}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer d-flex justify-content-center">
                                                            <button type="button" className="btn btn-gris" data-bs-dismiss="modal"><i className="fa-solid fa-ban"></i> Cancelar</button>
                                                            <button type="button" className="btn btn-morado" onClick={() => onDescompartirFolder(folder.id, destinataryFolder)}>
                                                                Aceptar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- Fin Modal Confirmar Eliminación --> */}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            
                {/* <!-- Documentos en TABLA --> */}
                <div className="oculto" id="documentos-filtrados">
                    <div className="documentos document-grid" id="documents">
                        <table className="table-hover">
                            <thead id="tabla-documentos">
                                <tr className="fila-tabla">
                                    <th className="encabezado-tabla"></th>
                                    <th className="encabezado-tabla pl-2">Nombre </th>
                                    {/* <th className="encabezado-tabla">Tamaño</th> */}
                                    <th className="encabezado-tabla">Ubicación</th>
                                    <th className="encabezado-tabla">Fecha</th>
                                </tr>
                            </thead>
                
                            <tbody>
                                {
                                    listFilesShared.map((file,index) => {
                                        const destinatarios = Array.isArray(file.destinataries) ? file.destinataries : [];
                                        return (
                                            <tr className="fila-tabla" key={index}>
                            
                                                {/* <!-- Imagen del Documento --> */}
                                                <td className="imagen">
                                                    <div className="rectangle-86">
                                                    </div>
                                                    <div className="doc"> DOC </div>
                                                    <a href="#/" className="option-link d-flex justify-content-center"><svg id="img-documento" alt="Icono de un Documento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 84" fill="none">
                                                        <path d="M23.5 7.44263C21.5109 7.44263 19.6032 8.17134 18.1967 9.46847C16.7902 10.7656 16.5 12.7748 16.5 14.6093V42.026V69.6926C16.5 71.527 17.5935 73.2863 19 74.5834C20.4065 75.8806 21.5109 76.6093 23.5 76.6093H68.5C70.4891 76.6093 72.3968 75.8806 73.8033 74.5834C75.2098 73.2863 75.5 71.527 75.5 69.6926V48.9426V28.1926L53.5 7.44263H23.5ZM21.5 12.526H51V27.526L70.5 28.1926V48.9426V71.1093H46.5H21.5V42.026V12.526ZM31 42.026V46.1093H45.5H61V42.026H31ZM31 56.1093V60.1093H41H50V56.1093H41H31Z" fill="black"/></svg>
                                                    </a>
                                                </td>
                            
                                                {/* <!-- Nombre del Documento --> */}
                                                <td className="nombre">
                                                    <div className="info-folder puntitos"> 
                                                        <a className="option-link nombre-carpeta" id="folder-name" href="#/">
                                                            <i className="fa-regular fa-file-lines me-2 ml-2 icono-doc"></i> {file.nombre}.{file.extension}
                                                        </a>
                                                    </div>
                                                </td>

                                                {/* <!-- Tamaño del Documento --> */}
                                                {/* <td className="tamaño">
                                                    
                                                </td> */}
                            
                                                {/* <!--  Ubicación del Documento --> */}
                                                <td className="ubicacion">
                                                    {file.carpeta} /
                                                </td>

                                                {/* <!-- Fecha de Creación del Documento --> */}
                                                <td className="fecha">
                                                    {format(new Date(file.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })}
                                                </td>
                        
                                                {/* <!-- Estrellita --> */}
                                                <td className="estrellita mr-4">
                                                    <form action="" method="POST">
                                                        <label className="star-checkbox">
                                                            <input type="checkbox" 
                                                                checked={favoritesFiles.includes(file.id)} 
                                                                name="favorito" id={`favorito-${file.id}`} onChange={(event) => handleFavoriteFileToggle(event, file.id)}
                                                            />
                                                            <span className="checkmark">
                                                                <i className="fa-regular fa-star"></i>
                                                                <i className="fa-solid fa-star"></i>
                                                            </span>
                                                        </label>
                                                    </form>
                                                </td>

                                                {/* <!-- Tres Puntitos --> */}
                                                <td className="tres-puntitos">
                                                    <div className="dropdown">
                                                        <div className="cursor" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i  className="fa fa-ellipsis-v fa-lg" ></i>
                                                        </div>
                        
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#archivoModalDocumento${file.id}`}>
                                                                    <i className="fa-solid fa-eye"></i> Ver detalles
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#confirmarModalDocumentoCompartidos${file.id}`}>
                                                                    <i className="fa-solid fa-share-nodes"></i> Descompartir
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a className="dropdown-item" href="#/">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16" ><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/><path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/></svg> Descargar
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#verVersionesDocumentoCompartidosModal${file.id}`}>
                                                                    <i className="fa-regular fa-copy"></i> Versiones
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a className="dropdown-item" href="#/" data-bs-toggle="modal" data-bs-target={`#firmarModalDoc${file.id}`}>
                                                                    <i className="fa-solid fa-pen-nib"></i> Firmar
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                            
                                                {/* <!-- Modal Detalle del Archivo --> */}
                                                <div className="modal" id={`archivoModalDocumento${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="archivoModalDocumentoLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                                        <div className="modal-content">
                            
                                                            <div className="modal-header">
                                                                <h5 className="modal-title col-11 d-flex justify-content-center" id="archivoModalLabel">
                                                                    Detalles
                                                                </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                            
                                                            <div className="modal-body">
                                                                {/* <p className="carpeta-tittle">Tamaño del Archivo</p>
                                                                <p className="info-carpeta">
                                                                </p> */}
                                
                                                                <p className="carpeta-tittle"> Ubicación</p>
                                                                <p className="info-carpeta">
                                                                    {file.carpeta} /
                                                                </p>
                                
                                                                <p className="carpeta-tittle"> Propietario </p>
                                                                <p className="info-carpeta">
                                                                    {file.pertenece}
                                                                </p>
                                
                                                                <p className="carpeta-tittle"> Compartido con </p>
                                                                <p className="info-carpeta">
                                                                    {destinatarios.map((nameDestinatary, index) => (
                                                                        <li key={index}>{nameDestinatary}</li>
                                                                    ))} 
                                                                </p>
                                
                                                                <p className="carpeta-tittle"> Creado </p>
                                                                <p className="info-carpeta">
                                                                    {format(new Date(file.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })}
                                                                </p>
                                
                                                                <p className="carpeta-tittle"> Descripción </p>
                                                                <p className="info-carpeta">
                                                                    {file.des}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- Fin Modal Detalle del Archivo --> */}
                            
                                                {/* <!-- Modal Firmar -->   */}
                                                <div className="modal" id={`firmarModalDoc${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="firmarModalDocLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered " role="document">
                                                        <div className="modal-content">

                                                            <div className="modal-header d-flex justify-content-center">
                                                                <h5 className="modal-title col-11 d-flex justify-content-center" id="firmarModalLabel">Firmar Documento</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            
                                                            <div className="modal-body">
                                                                <form method="POST">
                                                                    <p><b>Coloca el identificador que contiene el contrato.</b></p>
                                                                    <p className="fw-medium text-muted fs-7">El nombre del identificador debe ir dentro de corchetes "[ ]"</p>
                                                                    <input type="text" name="identificador" placeholder="Ejemplo: [Firma del cliente]" 
                                                                        required/>
                                                                    <canvas className="canvas" id="canvas{{ documento_pdf.id }}" width="430" height="200"></canvas>
                                                                    
                                                                    <input type="hidden" name="firma" id="firma{{ documento_pdf.id }}"/>
                                                                    <input type="hidden" name="carpeta" value="{{ documento_pdf.carpeta }}"/>
                                                                    <input type="hidden" name="documento" value="{{ documento_pdf.nombre }}"/>
                                                                    <input type="hidden" name="documentoId" value="{{ documento_pdf.id }}"/>
                                                                </form>
                                                            </div>

                                                            <div className="modal-footer d-flex justify-content-center">
                                                                <button className="btn btn-gris" type="button">Limpiar</button>
                                                                <button className="btn btn-morado" type="submit" name="firmaguardar">Guardar Firma</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                {/* ME QUEDÉ AQUI EN HACER QUE LAS VERSIONES DEL DOCUMENTO SE VEAN REFLEJADAS */}
                                                {/* <!--Modal tabla versiones--> */}
                                                <div className="modal" id={`verVersionesDocumentoCompartidosModal${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="verVersionesModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                                                        <div className="modal-content">
                                
                                                            <div className="modal-header">
                                                                <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel"> Versiones del documento "{file.nombre}.{file.extension}"
                                                                </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            
                                                            <div className="modal-body">
                                                                <div className="d-flex justify-content-end">
                                                                    <button className="btn btn-dark mb-2" data-bs-toggle="modal" data-bs-target="#subirVersionDocumentoCompartidos">
                                                                        + Subir versión
                                                                    </button>
                                                                </div>

                                                                <div className="row m-2 d-flex">
                                                                    <div className="col-md-12">   
                                                                        <div className="row">
                                                                            <div className="col-2 fw-bold">Versión</div>
                                                                            <div className="col-2 fw-bold">Usuario</div>
                                                                            <div className="col-3 fw-bold">Descripción</div>
                                                                            <div className="col-3 fw-bold">Fecha de Creación</div>
                                                                            <div className="col-2 fw-bold">Acciones</div>
                                                                        </div>

                                                                        {versions.map( (vers,vindex)  => (
                                                                            <div className="row mb-2">
                                                                                <div className="col-2"> {vers.version} </div>
                                                                                <div className="col-2"> {vers.usuario} </div>
                                                                                <div className="col-3"> {vers.descripcion} </div>
                                                                                <div className="col-3"> {format(new Date(vers.fecha_de_creacion), "dd 'de' MMMM 'de' yyyy", { locale: es })} </div>
                                                                                
                                                                                <div className="col-2 p-0 d-flex">
                                                                                    <div className="btn-version">
                                                                                        <a href="#/" className="btn" target="_blank">
                                                                                            <i className="fa-regular fa-eye"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                    
                                                                                    <div className="btn-version">
                                                                                        <a href="#/" className="btn">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16" >
                                                                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                                                            </svg>
                                                                                        </a>
                                                                                    </div>
                                                                                        
                                                                                    <div className="btn-version">
                                                                                        <a href='#/' className="eliminarButton btn" type="button" data-bs-toggle="modal" data-bs-target="#confirmarVersionModal2Doc" onClick={openModelDeleteFileVersions} idVersion={vers.id}>
                                                                                            <i className="fa-solid fa-trash-can"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <!-- Subir versión --> */}
                                                <div className="modal" id={`subirVersionDocumentoCompartidos${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="versionesModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                                        <div className="modal-content">
                                    
                                                            <div className="modal-header">
                                                                <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel"> Subir Versión de Documento "{file.nombre}.{file.extension}"</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                    
                                                            <div className="modal-body">
                                                                <form method="POST" id="nueva-carpeta-form" acceptCharset="UTF-8" encType="multipart/form-data">
                                                                    <label htmlFor=""> Archivo </label>
                                                                    <input type="file" id="compartirNombre" onChange={handleArchivoVersions} name=""/>
                                                                </form>
                                                            </div>

                                                            <div className="modal-footer d-flex justify-content-center">
                                                                <button type="submit" name="versionado_submit" className="btn btn-morado" onClick={onSendVersion}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                                                    width="16" height="16" fill="currentColor" className="bi bi-upload btn-upload-icon" viewBox="0 0 16 16">
                                                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                                                    </svg> Subir
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- Fin Modal --> */}
                            
                                                {/* <!-- Modal Descompartir--> */}
                                                <div className="modal" id={`confirmarModalDocumentoCompartidos${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="confirmarModalDocLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                                        <div className="modal-content">
                            
                                                            <div className="modal-header">
                                                                <h5 className="modal-title col-11 d-flex justify-content-center" id="confirmarModalLabel"> Dejar de Compartir </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            
                                                            <div className="modal-body">
                                                                <p>¿Estás seguro de que deseas descompartir este documento: <b>"{file.nombre}.{file.extension}"</b>?</p>
                                                                <p> Usuarios con los que compartiste este documento:</p>
                                                                <ul>
                                                                    {destinatarios.map((nameDestinatary, index) => (
                                                                        <li key={index}>{nameDestinatary}</li>
                                                                    ))}  
                                                                </ul>

                                                                <div className='d-flex '>
                                                                <select className="select-compartir" value={destinatary} onChange={(e) => setDestinatary(e.target.value)}>
                                                                    <option value="">Selecciona un usuario</option>
                                                                    {destinatarios.map((nameDestinatary, index) => (
                                                                        <option key={index} value={nameDestinatary}>{nameDestinatary}</option>
                                                                    ))}
                                                                </select>
                                                                </div>
                                                            </div>

                                                            <div className="modal-footer d-flex justify-content-center">
                                                                <button type="button" className="mr-2 btn btn-gris" data-bs-dismiss="modal"><i className="fa-solid fa-ban" > </i> Cancelar </button>
                                                                <button type="button" className="btn btn-morado" idFile={file.id} onClick={onDescompartirFile}>Aceptar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- Fin Modal Confirmar Eliminación --> */}

                                                {/* <!-- Modal Eliminar Versión --> */}
                                                <div className="modal" id={`confirmarVersionModal2Doc${file.id}`} tabIndex="-1" role="dialog" aria-labelledby="confirmarVersionModalDocLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                                        <div className="modal-content">

                                                            <div className="modal-header ">
                                                                <h5 className="modal-title col-11 d-flex justify-content-center"id="confirmarModalLabel">Confirmar Eliminar Documento</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                                
                                                            <div className="modal-body">
                                                                <p>¿Estás seguro de que deseas eliminar la version <b>
                                                                    "{file.nombre}.{file.extension}"</b>?</p>
                                                                <p className ="nota">Esta versión del documento será enviada a la <b> Papelera de Reciclaje. </b></p>
                                                            </div>

                                                            <div className="modal-footer d-flex justify-content-center">
                                                                <button type="button" className="mr-2 btn btn-gris" data-bs-dismiss="modal"><i className="fa-solid fa-ban"
                                                                > </i> Cancelar </button>
                                                                <button type="button" className="btn btn-danger"><i className="fa-solid fa-trash-can"> </i> Eliminar </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- Fin Modal Eliminar Versión --> */}
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
