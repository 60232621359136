import React from 'react'
import {Routes, Route, BrowserRouter} from "react-router-dom"
import { Header } from '../components/shared/Header'
import { Footer } from '../components/shared/Footer'
import { Login } from '../components/shared/Login'

// Administrador
import { WelcomeAdmi } from '../components/administrador/WelcomeAdmi'
import { RegistroEmpresa } from '../components/RegistroEmpresa'
import { VistaAdmiRecientes } from '../components/administrador/VistaAdmiRecientes'
import { VistaAdmiFavoritos } from '../components/administrador/VistaAdmiFavoritos'
import { VistaAdmiEquipos } from '../components/administrador/VistaAdmiEquipos'
import { VistaAdmiUsers } from '../components/administrador/VistaAdmiUsers'
import { VistaAdmiCompartidos } from '../components/administrador/VistaAdmiCompartidos'
import { VistaAdmiPapelera } from '../components/administrador/VistaAdmiPapelera'
import { VistaContenidoCarpetaAdmi } from '../components/administrador/VistaContenidoCarpetaAdmi'

// Usuario
import { WelcomeUser } from '../components/usuario-trabajador/WelcomeUser'
import { VistaUserRecientes } from '../components/usuario-trabajador/VistaUserRecientes'
import { VistaUserFavoritos } from '../components/usuario-trabajador/VistaUserFavoritos'
import { VistaUserEquipos } from '../components/usuario-trabajador/VistaUserEquipos'
import { VistaUserCompartidos } from '../components/usuario-trabajador/VistaUserCompartidos'
import { VistaUserPapelera } from '../components/usuario-trabajador/VistaUserPapelera'
import { RegistroTrabajador } from '../components/RegistroTrabajador'
import { AuthProvider,AuthRoute} from './Auth'

import { VistaContenidoCarpetaUser } from '../components/usuario-trabajador/VistaContenidoCarpetaUser'
// import { NavbarPanel } from '../components/paneldecontrol/NavbarPanel'
import { Navbar } from '../components/shared/Navbar'
import { Dashboard } from '../components/paneldecontrol/Dashboard'
import { Equipos } from '../components/paneldecontrol/Equipos'
import { Usuarios } from '../components/paneldecontrol/Usuarios'
import { Backup } from '../components/paneldecontrol/Backup'
import { Facturacion } from '../components/paneldecontrol/Facturacion'
import { Politicas } from '../components/paneldecontrol/Politicas'
import { DetallesEquipos } from '../components/paneldecontrol/DetallesEquipos'

export const MisRutas = () => {

    return (
        <BrowserRouter>
            <AuthProvider>
                
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/login' element={<Login  />} />
                    <Route path='/admin/registroempresa' element={<AuthRoute rolPage={'admin'}> <RegistroEmpresa/> </AuthRoute>}/>
                    <Route path='/registrotrabajador' element={<AuthRoute rolPage={'editor'}> <RegistroTrabajador/> </AuthRoute>} />

                    <Route
                        path="/*" 
                        element={
                            <>
                                <Header />
                                
                                <Navbar /> 
                                <Routes>
                                    {/* Administrador */}
                                    <Route path='/welcomeadmi' element={<AuthRoute rolPage={'admin'}> <WelcomeAdmi /> </AuthRoute>}/> 
                                    <Route path='/welcomeadmi/:id' element={<AuthRoute  rolPage={'admin'}> <VistaContenidoCarpetaAdmi /> </AuthRoute> }/>
                                    <Route path='/recientesadmi' element={<AuthRoute rolPage={'admin'}> <VistaAdmiRecientes /> </AuthRoute>}/> 
                                    <Route path='/favoritosadmi' element={<AuthRoute rolPage={'admin'}> <VistaAdmiFavoritos /> </AuthRoute> }/>
                                    <Route path='/equiposadmi' element={<AuthRoute rolPage={'admin'}> <VistaAdmiEquipos /> </AuthRoute> }/>
                                    <Route path='/usuariosadmi' element={<AuthRoute rolPage={'admin'}> <VistaAdmiUsers /> </AuthRoute> }/>
                                    <Route path='/compartidosadmi' element={<AuthRoute rolPage={'admin'}> <VistaAdmiCompartidos /> </AuthRoute> }/>
                                    <Route path='/papeleraadmi' element={<AuthRoute rolPage={'admin'}> <VistaAdmiPapelera /> </AuthRoute> }/>

                                    {/* Panel de Administrador */}
                                    <Route path='/paneladmi' element={<AuthRoute rolPage={'admin'}> <Dashboard /> </AuthRoute> }/>
                                    <Route path='/paneladmi/usuariospanel' element={<AuthRoute rolPage={'admin'}> <Usuarios /> </AuthRoute> }/>

                                    <Route path='/paneladmi/equipospanel' element={<AuthRoute rolPage={'admin'}> <Equipos /> </AuthRoute> }/>
                                    <Route path='/paneladmi/detallesequipos' element={<AuthRoute rolPage={'admin'}> <DetallesEquipos /> </AuthRoute> }/>
                                    
                                    <Route path='/paneladmi/backuppanel' element={<AuthRoute rolPage={'admin'}> <Backup /> </AuthRoute> }/>
                                    <Route path='/paneladmi/facturacionpanel' element={<AuthRoute rolPage={'admin'}> <Facturacion /> </AuthRoute> }/>
                                    <Route path='/paneladmi/politicaspanel' element={<AuthRoute rolPage={'admin'}> <Politicas /> </AuthRoute> }/>
                                    
                                    {/* Usuario Trabajador */}
                                    <Route path='/welcomeuser'  element={<AuthRoute  rolPage={'editor'} ><WelcomeUser /></AuthRoute>}/> 
                                    <Route path='/recientesuser'  element={<AuthRoute  rolPage={'editor'} ><VistaUserRecientes /></AuthRoute>}/> 
                                    <Route path='/favoritosuser' element={<AuthRoute  rolPage={'editor'}> <VistaUserFavoritos /> </AuthRoute> }/>
                                    <Route path='/equiposuser' element={<AuthRoute  rolPage={'editor'}> <VistaUserEquipos /> </AuthRoute> }/>
                                    <Route path='/compartidosuser' element={<AuthRoute  rolPage={'editor'}> <VistaUserCompartidos /> </AuthRoute> }/>
                                    <Route path='/papelerauser' element={<AuthRoute rolPage={'editor'}> <VistaUserPapelera /> </AuthRoute> }/>
                                    <Route path='/welcomeuser/:id' element={<AuthRoute rolPage={'editor'}> <VistaContenidoCarpetaUser /> </AuthRoute> }/>
                                </Routes>

                                <Footer />
                            </>
                        }
                    />

                    <Route path='*' element={(
                        <>
                            <h2>Error 404</h2>
                            <strong>Esta página no existe</strong>
                        </>
                    )} />
                </Routes>
            </AuthProvider>


        </BrowserRouter>
    )
}
