import React, { useState, useEffect } from 'react';

const Filtros = () => {
    const [filtroSeleccionado, setFiltroSeleccionado] = useState('all');

    useEffect(() => {
        showDocuments(filtroSeleccionado);
    }, [filtroSeleccionado]);

    const handleFiltro = (option) => {
        setFiltroSeleccionado(option);
    };

    // Función para mostrar los documentos según la opción de filtro seleccionada
    const showDocuments = (option) => {
        const carpetasFiltradas = document.getElementById('carpetas-filtradas');
        const documentosFiltrados = document.getElementById('documentos-filtrados');
        // const documentosFiltradosVersiones = document.getElementById('documentos-filtrados-versiones');

        // Ocultar todos los divs al inicio
        carpetasFiltradas.style.display = 'none';
        documentosFiltrados.style.display = 'none';
        // documentosFiltradosVersiones.style.display = 'none';

        // Mostrar el div correspondiente según la opción seleccionada
        if (option === 'all') {
            carpetasFiltradas.style.display = 'block';
            documentosFiltrados.style.display = 'block';
            // documentosFiltradosVersiones.style.display = 'block';

        } else if (option === 'archivos') {
            documentosFiltrados.style.display = 'block';
            // documentosFiltradosVersiones.style.display = 'block';
            carpetasFiltradas.style.display = 'none';

        } else if (option === 'carpetas') {
            carpetasFiltradas.style.display = 'block';
            documentosFiltrados.style.display = 'none';
            // documentosFiltradosVersiones.style.display = 'none';
        }
    };

    return (
        <div className="flex-grow-1 ">
            <ul className="nav nav-underline">
                <li className="nav-item">
                    <a
                        className={`nav-link ${filtroSeleccionado === 'all' ? 'active' : ''}`}
                        href="#/"
                        onClick={() => handleFiltro('all')}
                    >
                        Todos
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${filtroSeleccionado === 'archivos' ? 'active' : ''}`}
                        href="#/"
                        onClick={() => handleFiltro('archivos')}
                    >
                        Archivos
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${filtroSeleccionado === 'carpetas' ? 'active' : ''}`}
                        href="#/"
                        onClick={() => handleFiltro('carpetas')}
                    >
                        Carpetas
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Filtros;
