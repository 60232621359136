import { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';

export const usePapeleraFiles = (user=null,idFather=null) => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);


    useEffect(() => {   
        const fetchData = async () => {
            setLoading(true);
            setError('');

            try {
                var response = {}
                if(idFather==null){ // Estoy en home
                    
                    if (user.rol === "admin") {
                        // Es un usuario administrador
                        response = await axios.get(`${urlHostTenant}/documents/papeleraDocumento?usuario=${user.username}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        );
                        // http://hyperoxbiz.localhost:8000/documents/papeleraDocumento/
                    } else {
                        response = await axios.get(`${urlHostTenant}/documents/papeleraDocumento?usuario=${user.nombreCompleto}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        )
                        console.log(response)
                    }
                    
                }
                console.log('files papelera', response)
                setFiles(response.data.data);
            } catch (error) {
                setError(error);
            }

            setLoading(false);
        };

        fetchData();
    }, [user,idFather]);

    return {files, loading, error};
}

