// import React from 'react'
// import { Navigate } from 'react-router-dom';
import React from "react";
import { Navigate } from "react-router-dom";

const AuthContext = React.createContext();

function AuthProvider({children}) {
    // const navigate = useNavigate()
    const [user,setUser] = React.useState(() => {
        // Cargar el usuario desde localStorage al iniciar la aplicación
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    })
    
    const login = (user) => {
        setUser(user)
        localStorage.setItem('user', JSON.stringify(user));
    }

    const logout = () => {
        setUser(null)
        localStorage.removeItem('user');
    }

    const auth = { user,login,logout }

    return(
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    )
}

function useAuth(){
    const auth = React.useContext(AuthContext)
    return auth
}

function AuthRoute(props){
    const auth = useAuth()
    // const navigate = useNavigate()

    if(!auth.user){
        return <Navigate to='/login'></Navigate>        
    }

    const rolPage = props.rolPage
    console.log(auth.user,rolPage)
    // console.log(user.rol, rolPage )
    if(auth.user.rol !== rolPage){
        return <Navigate to='/login'></Navigate>      }

    return props.children
}

export {
    AuthProvider,
    useAuth,
    AuthRoute
}