import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useTeams = () => {
    const [teams, setTeams] = useState([]); 
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 

    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);


    console.log('Teams:', teams)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${urlHostTenant}/teams/team/`,
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
                // http://hyperox.localhost:8000/teams/team/
                setTeams(response.data.data); 
            } catch (e) {
                setError(`Error al cargar equipos: ${e.message}`); 
            } finally {
                setLoading(false);
            }
        };

        fetchData(); 
    }, []); 

    useEffect(() => {
        document.title = 'Equipos - Lyvian'; // Cambia el título de la página cuando el componente se monta

        return () => {
            document.title = 'Lyvian'; // Restaura el título predeterminado cuando el componente se desmonta
        };
    }, []);

    return { teams, loading, error }; 
};

export default useTeams;
