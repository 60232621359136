import React, { useState } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Modal from './Modal.js'
const DocumentModal = ({ documentUrl, onClose }) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const headers = {
    "Accept": "application/json",

  };

  // https://store-files-in-s3.s3.ap-south-1.amazonaws.com/image-Fri+Mar+08+2024+17%3A45%3A07+GMT%2B0530+(India+Standard+Time).png
  const bodyContent = (
    <DocViewer documents={[{ "uri": documentUrl}]} pluginRenderers={DocViewerRenderers} />
  )

  return (
    <Modal 
      id={'visualizeDocumentModal'}
      title={'Documento'}
      body = {bodyContent}
    >

    </Modal>
  );
};

export default DocumentModal;