import React from 'react'
import { Header } from '../shared/Header'
import { Navbar } from '../shared/Navbar'
import { Footer } from '../shared/Footer'

export const DetallesEquipos = () => {
    return (
        <div>
            
        </div>
    )
}
