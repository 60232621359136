import React from 'react';

// import {NavbarAdmi} from '../../shared/NavbarAdmi';

import useTeams from '../../hooks/useTeams';
import useFolders from '../../hooks/useFolders';
import { useAuth } from '../../router/Auth';
import useUsers from '../../hooks/useUsers';
import useFiles from '../../hooks/useFiles';
import { useNavigate,useLocation,useParams} from 'react-router-dom';


export const VistaAdmiEquipos = () => {
  const {user} = useAuth();

  const location = useLocation()

  const {id: idFather} = useParams()
  const params = new URLSearchParams(location.search);
  const nombreFather = params.get('parent')

  const nombreEquipo = params.get('equipo')
    
  const { teams, loading, error } = useTeams();

  const {folders, setIsLoading} = useFolders(user,idFather,nombreFather)
  const {files, setIsLoading: filesIsLoading} = useFiles(user,idFather)

  return (
    <div className="vista-admin-equipos d-flex">
      {/* <NavbarAdmi nombreEquipo={params.get('equipo')} /> */}
      {teams.map((team, folder) => (
        <div className="card equipos-card mt-2 me-5" key={team.id}>
          <div className="d-flex justify-content-end cursor puntitos-equipos">
            <i id="myCheckbox" data-bs-toggle="modal" data-bs-target="#equipoModal" className="fa-solid fa-ellipsis-vertical"></i>
          </div>

          <img src={team.imagen} id="img-equipo" className='d-flex' alt={''} />

          <div className="card-body">
            <h5>
              <a className='m-0 nombre-equipo text-decoration-none' href="#/">
                {team.nombre_equipo}
              </a>
            </h5>
            <p className="card-text"><small className="text-muted">
              {team.trabajadores} integrantes
            </small></p>

            <div className="storage-equipo">
              <div className="progress">
                <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div className="space-info d-flex justify-content-between">
                <div>0</div>
                <div>50GB</div>
                <div>1TB</div>   
              </div>
            </div>

            <a href={'welcomeadmi/'+team.id + '?parent='+team.nombre_equipo + '&equipo='+team.nombre_equipo} className="d-flex justify-content-end detalles-equipos text-decoration-none"><b>Ver archivos</b></a>
          </div>
        </div>
      ))}
    </div>
  );
};
