
import './App.css';
// import { Login } from './components/shared/Login';
import { MisRutas } from './router/MisRutas';

function App() {
  return (
    <div className='body'>

      {/* <Login /> */}
      <MisRutas/>

    </div>
  );
}

export default App;
