import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useAdministradores = () => {
    const [admins, setAdmins] = useState([]); 
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 

    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);  

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${urlHostTenant}/users/customuser/`,
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
                // http://hyperoxbiz.localhost:8000/users/customuser/
                setAdmins(response.data.data); 
            } catch (e) {
                setError(`Error al cargar Administradores: ${e.message}`); 
            } finally {
                setLoading(false);
            }
        };

        fetchData(); 
    }, []); 

    useEffect(() => {
        document.title = 'Admiinistradores - Lyvian'; // Cambia el título de la página cuando el componente se monta

        return () => {
            document.title = 'Lyvian'; // Restaura el título predeterminado cuando el componente se desmonta
        };
    }, []);

    return { admins, loading, error }; 
};

export default useAdministradores;
