import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useFavoritesFiles = (user=null, idFather=null, nombreFather=null) => {
    const [filesFavorite, setFilesFavorites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    // const [createFolderTrigger, setCreateFolderTrigger] = useState(false);

    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError('');

            try {
                var response = {}
                if(idFather==null){ // Estoy en home
                    if (user.rol === "admin") {
                        // Es un usuario administrador
                        response = await axios.get(`${urlHostTenant}/documents/favoritedocuments?usuario=${user.username}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        );
                        // http://hyperoxbiz.localhost:8000/documents/documents/
                        console.log('files', response)
                    } else {
                        // worker
                        response = await axios.get(`${urlHostTenant}/documents/favoritedocuments?usuario=${user.nombreCompleto}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        );
                        console.log('files', response)
                    }
                }
                console.log('files', response)
                setFilesFavorites(response.data.data);
            } catch (error) {
                setError(error);
            }

            setLoading(false);
        };
    
        fetchData();
    }, [user, idFather, nombreFather]);

    return { filesFavorite, loading, error};
    };

export default useFavoritesFiles;