import { useAuth } from "../../router/Auth";
import { NavbarPanel } from "../paneldecontrol/NavbarPanel";
import { NavbarAdmi } from "./NavbarAdmi";
import { NavbarUser } from "./NavbarUser";
import useTeams from '../../hooks/useTeams';


export const Navbar= () => {
    const {user} = useAuth();

    if(user.rol === 'admin' && window.location.pathname.includes('/paneladmi') === false){
        return <NavbarAdmi />
    }else if( user.rol === 'admin' &&  window.location.pathname.includes('/paneladmi') === true){
        return <NavbarPanel/>
    }else{
        return <NavbarUser/>
    }


}