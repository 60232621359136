import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useFolderShared = (user=null, idFather=null, nombreFather=null) => {
    const [foldersSharedForMe, setFoldersSharedForMe] = useState([]);
    const [foldersSharedByMe, setFoldersSharedByMe] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    // const [createFolderTrigger, setCreateFolderTrigger] = useState(false);
    
    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError('');

            try {
                var response = {}
                if(idFather==null){ // Estoy en home
                    if (user.rol === "admin") {
                        // Es un usuario administrador
                        response = await axios.get(`${urlHostTenant}/shared/shareFolder?usuario=${user.username}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        );
                        // http://hyperoxbiz.localhost:8000/shared/shareFolder/
                        console.log(response)
                    }else{
                        // Es un usuario trabajador
                        response = await axios.get(`${urlHostTenant}/shared/shareFolder?usuario=${user.nombreCompleto}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        );
                    }
                } else {
                    if(user.rol === "admin") {
                        response = await axios.get(`${urlHostTenant}/shared/shareFolder?idFather=${idFather}&usuario=${user.username}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        )
                    }else{
                        response = await axios.get(`${urlHostTenant}/shared/sharedFolder?idFather=${idFather}&usuario=${user.nombreCompleto}`,
                            {headers: {
                                Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                                'X-DTS-SCHEMA': xDtsSchemaTenant,
                            }}
                        )
                    }

                }

                // 
                console.log('sharePorMi', response.data.data['carpetas_compartidos_por_mi'])
                setFoldersSharedByMe(response.data.data['carpetas_compartidos_por_mi']);
                //

                console.log('shareConmigo', response.data.data['carpetas_compartidos_para_mi'])
                setFoldersSharedForMe(response.data.data['carpetas_compartidos_para_mi']);
            } catch (error) {
                setError(error);
            }

            setLoading(false);
        };
    
        fetchData();
    }, [user, nombreFather, idFather]);

    return { foldersSharedForMe,foldersSharedByMe, loading, error};
    };

export default useFolderShared;