import { useState, useEffect } from 'react';
import axios from 'axios';

import {useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


const useFolders = (user=null, idFather=null, nombreFather=null) => {
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
  const urlHostTenant = useSelector((state) => state.urlHost);
  // Este va para los headers
  const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema);


  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let nombreEquipo = params.get('equipo')

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');

      try {
        var response = {};
    
        if(idFather === null){ // Estoy en home
            if (user.rol === "admin") {
                // Es un usuario administrador
                response = await axios.get(`${urlHostTenant}/folders/subfolder?nombreFather=${user.username}&usuario=${user.username}`,
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
            } else {
                // Es un usuario trabajador
                response = await axios.get(`${urlHostTenant}/folders/subfolder?nombreFather=${user.nombreCompleto}&usuario=${user.nombreCompleto}`,
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
            }
        } else if (nombreEquipo !== null) { // Estoy en equipo
            if(user.rol === "admin") {
                response = await axios.get(`${urlHostTenant}/folders/subfolder?nombreFather=${nombreEquipo}&usuario=${user.username}`,
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
            } else { // worker
                response = await axios.get(`${urlHostTenant}/folders/subfolder?nombreFather=${nombreEquipo}&idFather=${idFather}&usuario=${user.nombreCompleto}`,
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
            }
        } else { // Estoy en subcarpeta
            if(user.rol === "admin") {
                response = await axios.get(`${urlHostTenant}/folders/subfolder?nombreFather=${nombreFather}&idFather=${idFather}&usuario=${user.username}`,
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
            } else { // worker
                response = await axios.get(`${urlHostTenant}/folders/subfolder?nombreFather=${nombreFather}&idFather=${idFather}&usuario=${user.nombreCompleto}`,
                    {headers: {
                        Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                        'X-DTS-SCHEMA': xDtsSchemaTenant,
                    }}
                );
            }
        }
    
        console.log('folders', response.data.data);
        setFolders(response.data.data);
    }  catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetchData();
  }, [user,nombreFather,idFather]);

  return { folders, loading, error};
};

export default useFolders;