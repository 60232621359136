import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useVersions = (idDocument) => {
    const [versions, setVersions] = useState([]);
    const [idFile, setIdFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const tenantDjangoUrl = useSelector((state) => state.tenantDjangoUrl);
    const urlHostTenant = useSelector((state) => state.urlHost);
    // Este va para los headers
    const xDtsSchemaTenant = useSelector((state) => state.xDtsSchema); 

    useEffect(() => {
      const fetchData = async () => {

        try {
            if(idFile != null){
              setLoading(true);
              setError('');
              var response = {}
              response = await axios.get(`${urlHostTenant}/documents/documentVersion/${idFile}`,
                {headers: {
                  Accept: "application/json","Content-Type": "application/json;charset=UTF-8",
                  'X-DTS-SCHEMA': xDtsSchemaTenant,
              }}
              );
              // http://hyperoxbiz.localhost:8000/documents/documentVersion/
              setVersions(response.data.data);
              console.log('version',response.data.data)
            }

        } catch (error) {
          setError(error);
        }

        setLoading(false);
      };
  
      fetchData();
    }, [idFile]);

    return { versions, setIdFile,loading, error};
  };

  export default useVersions;