import React from 'react'

export const Header = () => {
    return (
        <header className="header">
            
            <a href='/' className="d-block justify-content-start text-decoration-none">
                <p className="title-lyvian ml-4 mb-2 pt-2">LYVIAN </p>
                <p className="by-hyper">BY HYPER-OX</p>
            </a>
            
        </header>
    )
}
